import { SelectOption } from "../components/common/Select";
import { GROUPS_PROP_UPDATE, LOADING_GROUPS, PULLING_USERS, SOCIAL_MAP_UPDATE } from "../actions/types";
import { PlayerGroup, PlayerGroupUser } from "../api/groups.types";
import { AlderonFriendSkinny } from "../api/social.types";

export interface GroupsState {
    groups: PlayerGroup[];
    players: {
        [key: string]: PlayerGroupUser[]
    }
    invitations: {
        [key: string]: string
    }
    loading: boolean;
    friends: {
        [key: number]: AlderonFriendSkinny
    };
    pinned: string[];
    loadingFriends: boolean;
    groupsLoaded: boolean;
}

export const GROUPS_INITIAL_STATE: GroupsState = {
    groups: [],
    players: {},
    invitations: {},
    loading: false,
    friends: {},
    pinned: [],
    loadingFriends: false,
    groupsLoaded: false,
};

export const GroupsReducer = (state = GROUPS_INITIAL_STATE, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case LOADING_GROUPS:
            return {
                ...state,
                loading: payload,
            };
        case GROUPS_PROP_UPDATE:
            return {
                ...state,
                ...payload,
            };
    }
    return state;
}

export default GroupsReducer;