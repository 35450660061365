import React, { FunctionComponent } from "react";
import { Button, TableCell, TableRow } from '@mui/material';
import { AlderonFriend, AlderonFriendType, AlderonFriendTypeMapping } from "../../api/social.types";

const NON_CLICKABLE_TYPES = [
    AlderonFriendType.SELF,
    AlderonFriendType.FRIEND,
    AlderonFriendType.MUTED,
    AlderonFriendType.INCOMING,
    AlderonFriendType.OUTGOING,
]

interface FriendRowProps {
    friend: AlderonFriend
    hideAGID?: boolean;
    onPlayerSelected: (player: AlderonFriend) => void;
}

export const FriendRow: FunctionComponent<FriendRowProps> = ({ friend, hideAGID, onPlayerSelected: propsOnPlayerSelected }) => {
    const { id, display_name, type} = friend

    const onPlayerSelected = React.useCallback(() => {
        propsOnPlayerSelected(friend);
    }, [propsOnPlayerSelected, friend]);

    return (
        <TableRow key={id.display}>
            <TableCell>
                <Button onClick={onPlayerSelected} sx={{ textTransform: 'unset !important'}}>{display_name}</Button>
            </TableCell>
            {!hideAGID && <TableCell>{id.display}</TableCell>}
            <TableCell>{AlderonFriendTypeMapping[type]}</TableCell>
        </TableRow>
    );
}