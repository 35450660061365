import React from 'react';
import { Box, ButtonBase, Checkbox, FormControl, FormControlLabel, TextField, Typography } from '@mui/material';
import { PlayerGroupUser } from '../../api/groups.types';
import { formatAgid } from '../../common/utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faRemove } from '@fortawesome/free-solid-svg-icons';
import { AlderonFriendSkinny, AlderonFriendType, AlderonFriendTypeMapping, PLAYER_TRACK_TYPES } from '../../api/social.types';
import { ConfirmationDialog } from '../common/Modal';
import { SelectOption, Select } from '../common/Select';

interface GroupCardProps {
  player: PlayerGroupUser;
  loadingFriends: boolean;
  owner?: boolean;
  details?: AlderonFriendSkinny;
  onRemovePlayer: (player: PlayerGroupUser) => void;
  onChangeFriendType: (player: PlayerGroupUser, type: AlderonFriendType | null) => void;
}

export const GroupPlayerItem = ({ player, loadingFriends, owner, details, onRemovePlayer: onRemovePlayerProp, onChangeFriendType }: GroupCardProps) => {
  const [showEditTypeModal, setShowEditTypeModal] = React.useState(false);
  const [type, setType] = React.useState<SelectOption | null>(null);

  
  const onRemovePlayer = React.useCallback(() => {
    onRemovePlayerProp(player);
  }, [player, onRemovePlayerProp]);

  const onShowEditTypeModal = React.useCallback(() => {
    if (details) {
      setType(PLAYER_TRACK_TYPES.find((t) => t.value === details.type) ?? null);
    }
    setShowEditTypeModal(true);
  }, [player, details, setShowEditTypeModal]);

  const onHideEditTypeModal = React.useCallback(() => {
    setType(null);
    setShowEditTypeModal(false);
  }, [player, setShowEditTypeModal]);

  const onConfirmTypeChange = React.useCallback(() => {
    onChangeFriendType(player, type?.value as AlderonFriendType);
    onHideEditTypeModal();
  }, [player, type, onHideEditTypeModal, onChangeFriendType]);

  const onTrackTypeChanged = React.useCallback((item: SelectOption) => {
    setType(item);
  }, [setType]);


    return (
      <Box key={player.uuid} sx={{ mb: 2, p: 2, border: '2px solid', borderColor: 'divider', borderRadius: '4px', maxWidth: { xxs: '18rem', sm: '20rem'} }}>
        <ConfirmationDialog
              id="update-player"
              title={player.name}
              visible={showEditTypeModal}
              okButtonText='Save'
              cancelButtonText='Cancel'
              onConfirmed={onConfirmTypeChange}
              onClose={onHideEditTypeModal}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 4,
                  marginBottom: '2rem',
                }}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: {
                      xxs: 'flex-start',
                      sm: 'center',
                    },
                    flexDirection: {
                      xxs: 'column',
                      sm: 'row',
                    },
                  }}>
                    <Select sx={{ maxWidth: '20rem', width: '15rem' }} label={'Type'} value={type} items={PLAYER_TRACK_TYPES} onItemSelected={onTrackTypeChanged} clearable disableSearch />
                  </Box>
                </Box>
            </ConfirmationDialog>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography sx={{ fontSize: '1rem'}} variant="body1" color="text.primary">
            Name: {player.name}
          </Typography>
          {owner && (
            <ButtonBase sx={{ cursor: 'pointer' }} onClick={onRemovePlayer}>
              <Box component={FontAwesomeIcon} icon={faRemove} color='red' sx={{ width: 20, height: 20 }} />
            </ButtonBase>
          )}
        </Box>
        <Typography variant="body2" color="text.secondary">
          AGID: {formatAgid(player.agid)} {player.verified_agid ? "(Verified)" : "(Unverified)"}
        </Typography>
        {!loadingFriends && 
          (
            <Typography variant="body2" color="text.secondary">
              Type: {AlderonFriendTypeMapping[(details?.type as AlderonFriendType) ?? ''] ?? 'None'}
              {!details?.friends && (
                <ButtonBase sx={{ cursor: 'pointer', ml: '.5rem' }} onClick={onShowEditTypeModal}>
                  <Box component={FontAwesomeIcon} icon={faEdit} sx={{ width: 15, height: 15 }} />
                </ButtonBase>
              )}
            </Typography>
          )
        }
        {player.notes && (
          <Typography variant="body2" color="text.secondary">
            Notes: {player.notes}
          </Typography>
        )}
      </Box>
      );
    
}