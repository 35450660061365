import { SelectOption } from "../components/common/Select";
import { ServerInfo } from "./servers.types";

export interface AlderonIdentifier {
    identifier: number;
    display: string;
    platform?: string | null;
  }
  
  export interface AlderonAvatar {
    image: string;
    accent: string;
  }
  
  export interface AlderonUser {
    id: AlderonIdentifier;
    avatar: AlderonAvatar;
    admin: boolean;
    verified: boolean;
    display_name: string;
    email: string;
    attributes: any[];
    currency: {
      void: number;
      earned_pot: number;
      paid_pot: number;
    };
    tokens: {
      short: string;
      provider: string;
      report: string;
      login: string;
      analytics: string;
      deletion: string;
    };
    eos: {
      product_user_id: string;
      organization_user_id: string;
    };
    config: any[];
    crossplay: boolean;
    crosscommerce: boolean;
  }
  
  export interface AlderonActivity {
    game: {
      name: string;
      slug: string;
      branch: string;
      version: number;
    };
    server: {
      uuid: string;
      key: string;
      slug: string;
      provider: ServerProvider;
      instance: string;
    };
  }

  export enum ServerProvider {
    Gondowa = 'M0KGVG1DEZ',
    Panjura = 'G7W1GZDREW',
    Community = 'Community',
  }
  export const ServerProviderMapping = {
    [ServerProvider.Gondowa]: 'Gondowa',
    [ServerProvider.Panjura]: 'Panjura',
  };
    
  export enum AlderonFriendType {
    SELF = 'self',
    FRIEND = 'friend',
    ALLY = 'ally',
    FRIEND_OF_FRIEND = 'friend_of_friend',
    KOS = 'kos',
    WATCH = 'watch',
    OUTGOING = 'outgoing',
    INCOMING = 'incoming',
    MUTED = 'muted',
    NONE = '',
  }

  export const AlderonFriendTypeMapping = {
    [AlderonFriendType.FRIEND]: 'Friend',
    [AlderonFriendType.FRIEND_OF_FRIEND]: 'Friend of Friend',
    [AlderonFriendType.OUTGOING]: 'Outgoing',
    [AlderonFriendType.INCOMING]: 'Incoming',
    [AlderonFriendType.MUTED]: 'Muted',
    [AlderonFriendType.KOS]: 'KOS',
    [AlderonFriendType.WATCH]: 'Watch',
    [AlderonFriendType.SELF]: 'Self',
    [AlderonFriendType.ALLY]: 'Ally',
    [AlderonFriendType.NONE]: 'None',
  };
  
  export const ALDERON_FRIEND_TYPE_KEYS = Object.keys(AlderonFriendType);
  export const ALDERON_FRIEND_TYPE_VALUES = Object.values(AlderonFriendType);
  
  export interface AlderonFriend {
    id: AlderonIdentifier;
    avatar: AlderonAvatar;
    admin: boolean;
    display_name: string;
    activity?: AlderonActivity | null;
    friends: boolean;
    category: string;
    type: AlderonFriendType;
  }
  
export interface SocialGroup {
    server_info?: ServerInfo;
    server?: AlderonActivity;
    friends: AlderonFriend[];
    pinned: boolean;
    visited: boolean;
  }
  export interface SocialMap {
    [key: string]: SocialGroup;
  }
  
  export interface ProcessedSocialMap {
    map: SocialMap;
    friends: SelectOption<AlderonFriend>[];
    servers: { [key: string]: ServerInfo | undefined | null };
    pinned: string[];
  }
  
export interface AlderonFriendSkinny {
  agid: number;
  display_name: string;
  friends: boolean;
  type: AlderonFriendType;
}



export const PLAYER_TRACK_TYPES: SelectOption<string>[] = [
  { label: '', value: AlderonFriendType.NONE },
  { label: AlderonFriendTypeMapping[AlderonFriendType.KOS], value: AlderonFriendType.KOS },
  { label: AlderonFriendTypeMapping[AlderonFriendType.WATCH], value: AlderonFriendType.WATCH },
  { label: AlderonFriendTypeMapping[AlderonFriendType.FRIEND], value: AlderonFriendType.FRIEND },
  { label: AlderonFriendTypeMapping[AlderonFriendType.ALLY], value: AlderonFriendType.ALLY },
  { label: AlderonFriendTypeMapping[AlderonFriendType.FRIEND_OF_FRIEND], value: AlderonFriendType.FRIEND_OF_FRIEND },
  { label: 'None', value: AlderonFriendType.NONE },
];