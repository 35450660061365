import axios from 'axios';
import { AlderonFriend, SocialMap } from './social.types';
import { ServerInfo } from './servers.types';
import { social } from './mock/social';
import { AlderonConnectionError } from './auth';
const SERVER_ENDPOINT = 'https://servers.alderongames.com/pathOfTitans'


const SOCIAL_ENDPOINT = '/api/alderon/user/social'
const USER_SERVER_ENDPOINT = '/api/alderon/user/server'
const ALDERON_PLAYERS_ENDPOINT = '/api/alderon/alderon-players'
const SERVER_PINNED_ENDPOINT = `api/alderon/servers/player-pinned`

export const getUserServer = async(expandServerPlayers = false): Promise<SocialMap> => {
    // return social.item as SocialMap;
    try {
        const { data } = await axios.get(`${USER_SERVER_ENDPOINT}?expandAllPlayers=${expandServerPlayers}`, { timeout: 15000 }) // { data: mockSocialMap};
        const { item } = data as any;

        return item as SocialMap;
    } catch (e) {
        console.error(e);
    }

    return {} as SocialMap;
}

export const getSocialMap = async(): Promise<SocialMap> => {
    // return social.item as SocialMap;
    try {
        const { data } = await axios.get(SOCIAL_ENDPOINT) // { data: mockSocialMap};
        const { item } = data as any;

        return item as SocialMap;
    } catch (e: any) {
        if (e.response?.status === 400) {
            throw new AlderonConnectionError(e.response.data.message);
        }
        console.error(e);
    }

    return {} as SocialMap;
}

export const getServerDetails = async(key: string): Promise<ServerInfo>=> {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${key}`, { headers: {}})

        return data.data as ServerInfo;
    } catch (e) {
        console.error(e);
    }

    return {} as ServerInfo;
}

export const getUserDetails = async(playerIds: number[]): Promise<AlderonFriend[]>=> {
    try {
        const { data } = await axios.post(ALDERON_PLAYERS_ENDPOINT, { playerIds })

        return data.items as AlderonFriend[];
    } catch (e) {
        console.error(e);
    }

    return [] as AlderonFriend[];
}

export const getServerPlayers = async(serverKey: string): Promise<SocialMap> => {
    try {
        const { data } = await axios.get(`${USER_SERVER_ENDPOINT}/${serverKey}?expandAllPlayers=${true}`) // { data: mockSocialMap};
        const { item } = data as any;

        return item as SocialMap;
    } catch (e) {
        console.error(e);
    }

    return {} as SocialMap;
}

export const pinServer = async(serverKey: string): Promise<boolean> => {
    try {
        const { data } = await axios.post(`${SERVER_PINNED_ENDPOINT}/${serverKey}`) // { data: mockSocialMap};
        const { success } = data as any;

        return success;
    } catch (e) {
        console.error(e);
    }

    return false;
}

export const unpinServer = async(serverKey: string): Promise<boolean> => {
    try {
        const { data } = await axios.delete(`${SERVER_PINNED_ENDPOINT}/${serverKey}`) // { data: mockSocialMap};
        const { success } = data as any;

        return success;
    } catch (e) {
        console.error(e);
    }

    return false;
}