import { Alert, Slide, SlideProps, Snackbar } from "@mui/material";
import { NotificationItem } from "./types";
import React, { FunctionComponent, ReactElement } from "react";

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

export interface NotificationProps {
  item: NotificationItem;
  onClose: (id: string) => void;
}

export const Notification = ({ item, onClose: propsOnClose }: NotificationProps): ReactElement<NotificationProps> => {
    const onClose = React.useCallback(() => {
      propsOnClose(item.id);
    }, [item.id, propsOnClose]);

    return (
      <Snackbar key={item.id} disableWindowBlurListener open={true} autoHideDuration={item.duration ?? 4000} TransitionComponent={SlideTransition} onClose={onClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
            severity={item.severity}
            variant="filled"
            sx={{ width: '100%' }}
            onClose={onClose}
          >
            {item.message}
          </Alert>
      </Snackbar>
    );
}