import { AlderonConnectionError, User, alderonConnect, alderonDisconnect, initiateResetPassword as initiateResetPasswordApi, resetPassword as resetPasswordApi } from "../api";
import { DispatchFunction, GetStateFunction } from "../reducers";
import { addError, addSuccess } from "./notifications";
import { USER_UPDATE } from "./types";


export const updateUser = (user?: User): any => async (dispatch: DispatchFunction) => {
    dispatch({ type: USER_UPDATE, payload: user });
}

export const checkHasScope = (requiredScope: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { user } = getState().auth;
    const scope = user?.scope || '';

    return scope.includes(requiredScope);
}
export const connectToAlderon = (email: string, password: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { user } = getState().auth;
    if (!user) {
        return;
    }
    const scope = user?.scope || '';
    const hasAlderonScope = scope.includes('dinosync/alderon');
    if (!hasAlderonScope) {
        return;
    }

    try {
        const result = await alderonConnect(email, password);
        if (!result) {
            throw new AlderonConnectionError('Failed to connect to Alderon');
        }
        dispatch(updateUser({ ...user, alderonConnected: true }));
        dispatch(addSuccess('Succesfully connected to Alderon'));
        return true;
    } catch(e) {
        if (e instanceof AlderonConnectionError) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError(`An unknown error occured, please try again`));
        }

        return false;
    }
}
export const disconnectFromAlderon = () => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { user } = getState().auth;
    if (!user) {
        return;
    }
    const scope = user?.scope || '';
    const hasAlderonScope = scope.includes('dinosync/alderon');
    if (!hasAlderonScope) {
        return;
    }

    try {
        const result = await alderonDisconnect();
        if (!result) {
            throw new AlderonConnectionError('Failed to disconnect from Alderon, please try again shortly');
        }
        dispatch(updateUser({ ...user, alderonConnected: false }));
        dispatch(addSuccess('Succesfully disconnected from Alderon'));
        return true
    } catch(e) {
        if (e instanceof AlderonConnectionError)  {
            dispatch(addError(e.message));
        } else {
            dispatch(addError(`An unknown error occured, please try again`));
        }

        return false
    }
}

export const initiateResetPassword = (email: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {

    try {
        const result = await initiateResetPasswordApi(email);
        if (!result) {
            throw new Error('Failed to initiate password reset, please try again shortly');
        }
        dispatch(addSuccess('If you have an account with that email, you will receive an email with instructions to reset your password shortly.'));
        return true
    } catch(e) {
        dispatch(addError(`Failed to initiate password reset, please try again shortly`));

        return false
    }
}

export const resetPassword = (id: string, code: string, password: string, confirmPassword: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {

    try {
        const result = await resetPasswordApi(id, code, password, confirmPassword);
        if (!result) {
            throw new Error('Failed to reset your password, please try again shortly');
        }
        dispatch(addSuccess('Password succesfully reset.'));
        return true
    } catch(e: any) {
        dispatch(addError(e.message || 'Failed to reset your password, please try again shortly'));

        return false
    }
}
