import axios from 'axios'
import moment from 'moment'

import {  AuthenticationResponse } from './auth.types'
import { PlatformInvitation, PlatformInviteError } from './platformInvites.types'
import { GenericError } from './types'

const INVITATIONS_ENDPOINT = `/api/platform/invitations`
const CREATE_INVITATION_ENDPOINT = `/api/platform/invitations/blank`

export const generateInviteLink = async(scopes: string): Promise<AuthenticationResponse> => {
    const payload = {
        scopes,
    }
    try {
        const { data } = await axios.post(CREATE_INVITATION_ENDPOINT, payload)
        const { invitationLink } = data
        if (!invitationLink) {
            throw new GenericError('Invitation link failed to generate. Please try again.')
        }

        return invitationLink
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new PlatformInviteError(e.response.data)
        }

        throw e
    }
}

export const getInvitations = async(): Promise<PlatformInvitation[]> => {
    try {
        const { data } = await axios.get(INVITATIONS_ENDPOINT)
        const { items } = data
        if (!items) {
            throw new GenericError('Failed to retrieve platform invitations.')
        }

        return items.map((item: PlatformInvitation) => {
            return {
                user_uuid: item.user_uuid,
                invitation_code: item.invitation_code,
                email: item.email,
                scopes: item.scopes,
                revoked: item.revoked,
                claimed: item.claimed,
                link: `${window.location.origin}/register?invitation=${item.invitation_code}`,
                created_at: moment(item.created_at).format('MM/DD/YYYY'),
            }
        })
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new PlatformInviteError(e.response.data)
        }

        throw e
    }
}
export const revokeInvitation = async(code: string): Promise<void> => {
    try {
        await axios.post(`${INVITATIONS_ENDPOINT}/${code}/revoke`)
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new PlatformInviteError(e.response.data)
        }

        throw e
    }
}

export const enableInvitation = async(code: string): Promise<void> => {
    try {
        await axios.post(`${INVITATIONS_ENDPOINT}/${code}/enable`)
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new PlatformInviteError(e.response.data)
        }

        throw e
    }
}
export const deleteInvitation = async(code: string): Promise<void> => {
    try {
        await axios.delete(`${INVITATIONS_ENDPOINT}/${code}`)
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new PlatformInviteError(e.response.data)
        }

        throw e
    }
}