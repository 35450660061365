import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import axios from 'axios'
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';

import store from './store';
import { App } from './App';

import theme from './theme';
import { AuthProvider } from './common/providers/AuthProvider';

if ('serviceWorker' in navigator) {
  console.log('Service Worker is supported');
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sw.js')
      .then((registration) => {
        console.log('Service Worker registered:', registration);
      })
      .catch((error) => {
        console.log('Service Worker registration failed:', error);
      });
  });
}

export const bootstrap = (async () => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  return Promise.resolve(
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <StylesProvider>
              <ThemeProvider theme={theme}>
                  <CssBaseline />
                    <AuthProvider>
                      <App />
                    </AuthProvider>
              </ThemeProvider>
          </StylesProvider>
        </Provider>
      </React.StrictMode>
    )
  );
})();
