import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonBase, Card, CardContent, TextField, Typography } from '@mui/material';
import { GROUP_TYPES, PlayerGroup } from '../../api/groups.types';
import { PlayerGroupUser } from '../../api/groups.types';
import { formatAlderonIdInput } from '../../common/utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faEdit, faRemove, faShare } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationDialog } from '../common/Modal';
import { GroupPlayerItem } from './GroupPlayerItem';
import { AlderonFriendSkinny, AlderonFriendType, AlderonFriendTypeMapping } from '../../api/social.types';
import { Select, SelectOption } from '../common/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


interface GroupCardProps {
  group: PlayerGroup;
  players: PlayerGroupUser[];
  friends: {
    [key: number]: AlderonFriendSkinny
  };
  loadingFriends: boolean;
  onDeleteGroup: (group: PlayerGroup) => void;
  onEditGroup: (group: PlayerGroup) => void;
  onAddPlayer: (group: PlayerGroup, agid: string | number, name: string) => void;
  onRemovePlayer: (group: PlayerGroup, player: PlayerGroupUser) => void;
  onShareGroup: (group: PlayerGroup) => void;
  onUnjoinGroup: (group: PlayerGroup) => void;
  onChangeFriendType: (player: PlayerGroupUser, type: AlderonFriendType | null) => void;
  onTrackAllPlayers: (group: PlayerGroup, players: PlayerGroupUser[]) => void;
  onUntrackAllPlayers: (group: PlayerGroup, players: PlayerGroupUser[]) => void;
}

export const GroupCard = ({ group, players, friends, loadingFriends, onDeleteGroup, onEditGroup, onAddPlayer, onRemovePlayer, onShareGroup, onUnjoinGroup, onChangeFriendType, onTrackAllPlayers, onUntrackAllPlayers}: GroupCardProps) => {
    const [expanded, setExpanded] = React.useState(false);
    const [openAddPlayerModal, setOpenAddPlayerModal] = React.useState(false);
    const [openRemovePlayerModal, seRemoveAddPlayerModal] = React.useState(false);
    const [openEditGroupModal, setEditGroupModal] = React.useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
    const [openConfirmUnjoin, setOpenConfirmUnjoin] = React.useState(false);
    const [removePlayerItem, setRemovePlayerItem] = React.useState<PlayerGroupUser | null>(null);
    const [openTrackAllPlayers, setOpenTrackAllPlayers] = React.useState(false);
    const [openUntrackAllPlayers, setOpenUntrackAllPlayers] = React.useState(false);

    const [agid, setAgid] = React.useState<string | number>('');
    const [name, setName] = React.useState<string>('');
    const [groupName, setGroupName] = React.useState<string>('');
    const [groupType, setGroupType] = React.useState<SelectOption | null>(null);

    const onOpenAddPlayer = React.useCallback((e: any) => {
      e.stopPropagation();
      setAgid('');
      setName('');
      setOpenAddPlayerModal(true);
    }, []);
    const onOpenRemovePlayer = React.useCallback((player: PlayerGroupUser) => {
      setRemovePlayerItem(player);
      seRemoveAddPlayerModal(true);
    }, []);
    const onOpenEditGroupModal = React.useCallback((e: any) => {
      e.stopPropagation();
      setGroupName(group.name);
      setGroupType(GROUP_TYPES.find(item => item.value === group.type) ?? GROUP_TYPES[0]);
      setEditGroupModal(true);
    }, [group, setGroupName, setGroupType, setEditGroupModal]);
    const onOpenDeleteGroup = React.useCallback((e: any) => {
      e.stopPropagation();
      setOpenConfirmDelete(true);
    }, []);
    
    const onCloseDeleteModal = React.useCallback(() => {
      setOpenConfirmDelete(false);
    }, []);

    
    const onOpenUnjoinGroup = React.useCallback((e: any) => {
      e.stopPropagation();
      setOpenConfirmUnjoin(true);
    }, []);
    
    const onCloseUnjoinModal = React.useCallback(() => {
      setOpenConfirmUnjoin(false);
    }, []);

    const onCloseEditGroup = React.useCallback(() => {
      setGroupName('');
      setGroupType(null);
      setEditGroupModal(false);
    }, []);

    const onCloseAddPlayer = React.useCallback(() => {
      setOpenAddPlayerModal(false);
      setAgid('');
      setName('');
    }, []);

    const onCloseRemovePlayer = React.useCallback(() => {
      seRemoveAddPlayerModal(false);
    }, []);

    const onConfirmRemovePlayer = React.useCallback(() => {
      onCloseRemovePlayer();
      if (removePlayerItem) {
        onRemovePlayer(group, removePlayerItem);
      }
    }, [removePlayerItem, onRemovePlayer]);

    const onConfirmAddPlayer = React.useCallback(() => {
      onCloseAddPlayer();
      onAddPlayer(group, agid, name);
    }, [group, agid, name, onAddPlayer]);

    const onConfirmDeleteGroup = React.useCallback(() => {
      onCloseDeleteModal();
      onDeleteGroup(group);
    }, [group, onDeleteGroup]);

    const onConfirmUnjoinGroup = React.useCallback(() => {
      onCloseUnjoinModal();
      onUnjoinGroup(group);
    }, [group, onDeleteGroup]);
    const onConfirmEditGroup = React.useCallback(() => {
      onCloseEditGroup();
      onEditGroup({ ...group, name: groupName, type: groupType?.value });
    }, [group, groupName, groupType, onEditGroup]);

    const onShareGroupClicked = React.useCallback((e: any) => {
      e.stopPropagation();
      onShareGroup(group);
    }, [group, onShareGroup]);

    const onConfirmTrackAllPlayers = React.useCallback(() => {
      setOpenTrackAllPlayers(false);
      onTrackAllPlayers(group, players);
    }, [group, players, onTrackAllPlayers]);

    const onConfirmUntrackAllPlayers = React.useCallback(() => {
      setOpenUntrackAllPlayers(false);
      onUntrackAllPlayers(group, players);
    }, [group, players, onUntrackAllPlayers]);

    const showTrackAllPlayers = React.useCallback(() => {
      setOpenTrackAllPlayers(true);
    }, []);

    const showUntrackAllPlayers = React.useCallback(() => {
      setOpenUntrackAllPlayers(true);
    }, []);

    const closeTrackAllPlayers = React.useCallback(() => {
      setOpenTrackAllPlayers(false);
    }, []);

    const closeUntrackAllPlayers = React.useCallback(() => {
      setOpenUntrackAllPlayers(false);
    }, []);

    const onExpandChanged = React.useCallback(() => {
      setExpanded(!expanded);
    }, [expanded, setExpanded]);

    

  const preventClick = React.useCallback((e: any) => {
    e.stopPropagation();
  },[]);

    return (
      <Accordion expanded={expanded} onChange={onExpandChanged} key={group.uuid} sx={{ minWidth: 275, marginBottom: 2, overflow: 'visible', maxWidth: '70rem', overflow: 'hidden' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${group.uuid}-content`}
        id={`${group.uuid}-header`}
      >
            <ConfirmationDialog
              id={`delete-group-${group.uuid}`}
              title={`Delete Group: ${group.name}`}
              visible={openConfirmDelete}
              onClose={onCloseDeleteModal}
              onConfirmed={onConfirmDeleteGroup}
            >
            <Typography variant="body1" color="text.primary">
              Are you sure you want to delete this group?
            </Typography>
          </ConfirmationDialog>
            <ConfirmationDialog
              id={`unjoin-group-${group.uuid}`}
              title={`Remove Group: ${group.name}`}
              visible={openConfirmUnjoin}
              onClose={onCloseUnjoinModal}
              onConfirmed={onConfirmUnjoinGroup}
            >
            <Typography variant="body1" color="text.primary">
              Are you sure you want to remove this group?
            </Typography>
          </ConfirmationDialog>
          <ConfirmationDialog
            id={`remove-group-player-${group.uuid}-${removePlayerItem?.uuid}`}
            title={`Remove Player: ${removePlayerItem?.name}`}
            visible={openRemovePlayerModal}
            onClose={onCloseRemovePlayer}
            onConfirmed={onConfirmRemovePlayer}
          >
          <Typography variant="body1" color="text.primary">
            Are you sure you want to remove this player from the group?
          </Typography>
        </ConfirmationDialog>
          <ConfirmationDialog
            id={`track-all-players-${group.uuid}-${removePlayerItem?.uuid}`}
            title={group.name}
            visible={openTrackAllPlayers}
            onClose={closeTrackAllPlayers}
            onConfirmed={onConfirmTrackAllPlayers}
            okButtonText="Track All"
          >
          <Typography variant="body1" color="text.primary">
            Are you sure you want to track all players in this group?
          </Typography>
          <Typography variant="body1" color="text.primary" sx={{ mt: 2 }}>
            This will set the friend type to the group type of all players overwriting any existing friend type. 
          </Typography>
        </ConfirmationDialog>
          <ConfirmationDialog
            id={`untrack-all-players-${group.uuid}-${removePlayerItem?.uuid}`}
            title={group.name}
            visible={openUntrackAllPlayers}
            onClose={closeUntrackAllPlayers}
            onConfirmed={onConfirmUntrackAllPlayers}
            okButtonText="Untrack All"
          >
          <Typography variant="body1" color="text.primary">
            Are you sure you want to untrack all players in this group?
          </Typography>
        </ConfirmationDialog>
          <ConfirmationDialog
            id={`remove-group-player-${group.uuid}-${removePlayerItem?.uuid}`}
            title={`Remove Player: ${removePlayerItem?.name}`}
            visible={openRemovePlayerModal}
            onClose={onCloseRemovePlayer}
            onConfirmed={onConfirmRemovePlayer}
          >
          <Typography variant="body1" color="text.primary">
            Are you sure you want to remove this player from the group?
          </Typography>
        </ConfirmationDialog>
          <ConfirmationDialog
              id={`edit-group-${group.uuid}`}
              title={`Edit Group: ${group.name}`}
              visible={openEditGroupModal}
              onClose={onCloseEditGroup}
              onConfirmed={onConfirmEditGroup}
            >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              marginBottom: '2rem',
            }}>
            <TextField
              label="Name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
              <Box sx={{
                display: 'flex',
                alignItems: {
                  xxs: 'flex-start',
                  sm: 'center',
                },
                flexDirection: {
                  xxs: 'column',
                  sm: 'row',
                },
              }}>
                <Select sx={{ maxWidth: '20rem', width: '15rem'  }} label={'Group Type'} value={groupType} items={GROUP_TYPES} onItemSelected={setGroupType} clearable disableSearch />
              </Box>
            </Box>
          </ConfirmationDialog>
            <ConfirmationDialog
              id={`add-group-player-${group.uuid}-${removePlayerItem?.uuid}`}
              title={`Add Player: ${group.name}`}
              visible={openAddPlayerModal}
              onClose={onCloseAddPlayer}
              onConfirmed={onConfirmAddPlayer}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  label="AGID (required)"
                  value={agid}
                  onChange={(e) => setAgid(formatAlderonIdInput(e.target.value))}
                />
                <TextField
                  label="Name (optional)"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
          </ConfirmationDialog>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: '1rem', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '1.25rem'}} variant="h6" color="text.primary">
                {group.name} {!group.owner && group.owner_name && `(By ${group.owner_name})`}
              </Typography>
              {group.owner && (
                <Box onClick={preventClick} sx={{ mr: '-2.5rem'}}>
                  <ButtonBase sx={{ cursor: 'pointer' }} onClick={onOpenAddPlayer}>
                    <Box component={FontAwesomeIcon} icon={faAdd} sx={{ width: 15, height: 15 }} />
                  </ButtonBase>
                  <ButtonBase sx={{ cursor: 'pointer', ml: '.5rem' }} onClick={onOpenEditGroupModal}>
                    <Box component={FontAwesomeIcon} icon={faEdit} sx={{ width: 15, height: 15 }} />
                  </ButtonBase>
                  <ButtonBase sx={{ cursor: 'pointer', ml: '.5rem'  }} onClick={onShareGroupClicked}>
                    <Box component={FontAwesomeIcon} icon={faShare} sx={{ width: 15, height: 15 }} />
                  </ButtonBase>
                  <ButtonBase sx={{ cursor: 'pointer', ml: '.5rem'  }} onClick={onOpenDeleteGroup}>
                    <Box component={FontAwesomeIcon} icon={faRemove} color='red' sx={{ width: 15, height: 15 }} />
                  </ButtonBase>
                </Box>
              )}
              {!group.owner && (
                <Box>
                  <ButtonBase sx={{ cursor: 'pointer', ml: '.5rem'  }} onClick={onOpenUnjoinGroup}>
                    <Box component={FontAwesomeIcon} icon={faRemove} color='red' sx={{ width: 15, height: 15 }} />
                  </ButtonBase>
                </Box>
              )}
            </Box>
            <Typography color="text.secondary">
              Player Count: {players.length}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Type: {AlderonFriendTypeMapping[group.type as AlderonFriendType] ?? group.type}
            </Typography>
            </Box>
        </AccordionSummary>
        <AccordionDetails>
            {players.length > 1 && (
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: { xs: 'center', sm: 'flex-start' }, gap: 1 }}>
                <Button variant="outlined" sx={{ width: '45%', maxWidth: '10rem' }} onClick={showTrackAllPlayers}>
                  Track All
                </Button>
                <Button variant="outlined" sx={{ width: '45%', maxWidth: '10rem' }} onClick={showUntrackAllPlayers}>
                  Untrack All
                </Button>
              </Box>              
            )}
            <Box sx={{ borderBottom: '1px solid #ccc', mt: 2, mb: 2 }} />
            {players.length > 0 && (
              <Box sx={{ 
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(20rem, 1fr))', // This creates a responsive grid with items of minimum 150px width
                  gap: 2,
                  mt: 2,
               }}>
                {players.map((player) => (
                  <GroupPlayerItem key={player.uuid} player={player} owner={group.owner} loadingFriends={loadingFriends} onRemovePlayer={onOpenRemovePlayer} details={friends[player.agid]} onChangeFriendType={onChangeFriendType} />
                ))}
              </Box>
            )}
            {players.length === 0 && (
              <Typography variant="body2" color="text.secondary">
                No players in this group.
              </Typography>
            
            )}
        </AccordionDetails>
      </Accordion>
      );
    
}