/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';


import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { PlayerDetails } from '../../api';
import { FriendRow } from './FriendRow';
import { WithSmallMediaCheckProps, withSmallMediaCheck } from '../../common/hooks/withSmallMediaCheck';
import { AlderonFriend } from '../../api/social.types';

interface FriendsTableProps extends withAuthProps, WithSmallMediaCheckProps {
  friends: AlderonFriend[]
  loading: boolean;
  showPullServerPlayers: boolean;
  onPullServerPlayers: () => void;
  onPlayerSelected: (player: AlderonFriend) => void;
}


class FriendsTableComponent extends React.Component<FriendsTableProps> {

  render = () => {
    const { friends, showPullServerPlayers } = this.props;
    const hideAGID = this.props.smallerThan.sm;
    return (
      <TableContainer>
          <Table sx={{ width: '100%' }}>
              <TableHead>
                  <TableRow>
                      <TableCell>Name</TableCell>
                      {!hideAGID && <TableCell>AGID</TableCell>}
                      <TableCell>Status</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {friends?.length === 0 &&
                      <TableRow>
                          <TableCell colSpan={hideAGID ? 2 : 3}>No friends on this server</TableCell>
                      </TableRow>
                  }
                  {friends?.map((friend) => {
                      return <FriendRow onPlayerSelected={this.props.onPlayerSelected} key={friend.id.display} friend={friend} hideAGID={hideAGID} />;
                  })}
              </TableBody>
          </Table>
          {showPullServerPlayers &&
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button sx={{ mt: 2 }} variant="outlined" onClick={this.props.onPullServerPlayers} disabled={this.props.loading}>
              {this.props.loading && 
                <Box sx={{ display: 'flex', position:'absolute', top: 0, left: 0, right: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
                  <CircularProgress size={'1rem'} />
                </Box>
              }
              Pull Players
            </Button>
          </Box>
          }
      </TableContainer>
    );
  }
}


export const FriendsTable = withSmallMediaCheck(withAuth(FriendsTableComponent))