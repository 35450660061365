import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, TextField, Typography, Paper, Modal, FormControlLabel, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getInvitations, generateInviteLink, enablePlatformInvitation, revokePlatformInvitation, deletePlatformInvitation } from '../../actions/platformInvites'; // Adjust the import paths as needed
import { ConfirmationDialog } from '../common/Modal';
import { addError } from '../../actions/notifications';
import { PlatformInvitation } from '../../api/platformInvites.types';
import { ReduxState } from '../../reducers';
import CopyToClipboard from '../common/CopyToClipboard';

const SCOPE_MAPPING: Record<string, string> = {
  'user': 'User',
  'dinosync/admin': 'Admin',
  'dinosync/alderon': 'Alderon Connection',
  'dinosync/uber-admin': 'Uber Admin',
};

interface PlatformInvitationProps {
  getInvitations(): void;
  generateInviteLink: (scopes: string[]) => Promise<string>;
  enablePlatformInvitation: (code: string) => void;
  revokePlatformInvitation: (code: string) => void;
  deletePlatformInvitation: (code: string) => void;
  addError: (error: string) => void;
  invitations: PlatformInvitation[]
  inviteLink: string | null;
  userScopes: string;
  loading: boolean;
}
interface PlatformInvitationState {
  isModalOpen: boolean;
  isAdmin: boolean;
  isUberAdmin: boolean;
  hasAlderonConnection: boolean;
  generatedLink: string;
  isDeleteConfirmationOpen: boolean;
  isRevokeConfirmationOpen: boolean;
  currentInvitation: PlatformInvitation | null;
}

class PlatformInvitationComponent extends Component<PlatformInvitationProps, PlatformInvitationState> {
  state: PlatformInvitationState = {
    isModalOpen: false,
    isUberAdmin: false,
    isAdmin: false,
    hasAlderonConnection: false,
    generatedLink: '',
    isDeleteConfirmationOpen: false,
    isRevokeConfirmationOpen: false,
    currentInvitation: null,
  };

  componentDidMount(): void {
    this.props.getInvitations();
  }

  handleOpenModal = () => this.setState({ isModalOpen: true });
  handleCloseModal = () => this.setState({ isModalOpen: false, isAdmin: false, hasAlderonConnection: false });
  
  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [event.target.name]: event.target.checked } as unknown as PlatformInvitationState);
  };

  handleGenerateLink = async () => {
    const { isAdmin, hasAlderonConnection } = this.state;
    if (!isAdmin && !hasAlderonConnection) {
      this.props.addError('Please select at least one permission');
      return;
    }
    
    const scopes = [];
    if (isAdmin) scopes.push('dinosync/admin');
    if (hasAlderonConnection) scopes.push('dinosync/alderon');

    this.props.generateInviteLink(scopes);
    this.setState({ isModalOpen: false });
  };

  handleDelete = (invitation: PlatformInvitation) => {
    this.setState({ isDeleteConfirmationOpen: true, currentInvitation: invitation });
  };

  onDeleteConfirmation = () => {
    const { currentInvitation } = this.state;
    if (currentInvitation === null) {
      return;
    }

    this.props.deletePlatformInvitation(currentInvitation.invitation_code);
    this.onCloseDeleteConfirmation();
  }

  onCloseDeleteConfirmation = () => this.setState({ isDeleteConfirmationOpen: false, currentInvitation: null });

  handleRevoke = (invitation: PlatformInvitation) => {
    if (invitation.revoked) {
      this.props.enablePlatformInvitation(invitation.invitation_code);

      return;
    }
    this.setState({ isRevokeConfirmationOpen: true, currentInvitation: invitation });
  };

  confirmRevoke = () => {
    const { currentInvitation } = this.state;
    if (currentInvitation !== null) {
      this.props.revokePlatformInvitation(currentInvitation.invitation_code);
      this.closeRevokeConfirmation();
    }
  };

  closeRevokeConfirmation = () => this.setState({ isRevokeConfirmationOpen: false, currentInvitation: null });

  handleUberAdminCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isUberAdmin: event.target.checked });
    if (event.target.checked) {
      this.setState({ isAdmin: true, hasAlderonConnection: true });
    }
  }

  handleAdminCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((this.state.isUberAdmin) && !event.target.checked) {
      this.props.addError('Uber Admin will always have Admin permissions');

      return;
    }
    this.setState({ isAdmin: event.target.checked });
    if (event.target.checked) {
      this.setState({ hasAlderonConnection: true });
    }
  }

  handleAlderonCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((this.state.isAdmin || this.state.isUberAdmin) && !event.target.checked) {
      this.props.addError('Admin will always have Alderon Connection');

      return;
    }
    this.setState({ hasAlderonConnection: event.target.checked });
  }

  render() {
    const { isAdmin, isUberAdmin, hasAlderonConnection, isModalOpen, isRevokeConfirmationOpen, isDeleteConfirmationOpen } = this.state;
    const { invitations, inviteLink, userScopes, loading } = this.props;
    
    return (
      <Box>
        <Box sx={{ mb: 4 }}>
          <Button variant="contained" onClick={this.handleOpenModal}>Generate Invite Link</Button>
          <TextField
            fullWidth
            label="Generated Link"
            value={inviteLink ?? ''}
            InputProps={{ readOnly: true }}
            variant="outlined"
            sx={{ mt: 2, borderColor: 'primary.main', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'primary.main', }, }, }}
          />
        </Box>
        <ConfirmationDialog
          id="confirm-revoke"
          title="Revoke Invitation"
          visible={isRevokeConfirmationOpen}
          okButtonText='Revoke'
          cancelButtonText='Cancel'
          onConfirmed={this.confirmRevoke}
          onClose={this.closeRevokeConfirmation}>
          <Box>
            <Typography>Are you sure you want to revoke this invitation?</Typography>
            <Typography sx={{ mt: '1rem'}}>If a user has claimed this invitation their account will be disabled</Typography>
          </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          id="confirm-delete"
          title="Delete Invitation"
          visible={isDeleteConfirmationOpen}
          okButtonText='Delete'
          cancelButtonText='Cancel'
          onConfirmed={this.onDeleteConfirmation}
          onClose={this.onCloseDeleteConfirmation}>
          <Box>
            <Typography>Are you sure you want to delete this invite?</Typography>
            <Typography sx={{ mt: '1rem'}}>This cannot be undone.</Typography>
          </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          id="invite-link"
          title="Invite Link"
          visible={isModalOpen}
          okButtonText='Generate'
          cancelButtonText='Cancel'
          onConfirmed={this.handleGenerateLink}
          onClose={this.handleCloseModal}>
          <Box>
            <Typography variant="h6">Permissions</Typography>
            {userScopes.includes('dinosync/root') && <FormControlLabel control={<Checkbox checked={isUberAdmin} onChange={this.handleUberAdminCheckbox} />} label="Uber Admin" />}
            {(userScopes.includes('dinosync/root') || userScopes.includes('dinosync/uber-admin')) && <FormControlLabel control={<Checkbox checked={isAdmin} onChange={this.handleAdminCheckbox} />} label="Admin" />}
            <FormControlLabel control={<Checkbox checked={hasAlderonConnection} onChange={this.handleAlderonCheckbox} />} label="Alderon Connection" />
            <Typography sx={{ mt: '1rem' }}>Please send the link to trusted individuals only.</Typography>
          </Box>
        </ConfirmationDialog>
        <Typography variant="h5" sx={{ mb: 2 }}>Platform Invitations</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Role(s)</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Claimed</TableCell>
                <TableCell>Revoked</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
              </TableHead>
          <TableBody>
            {invitations.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>{loading ? '' : 'No invitations found'}</TableCell>
              </TableRow>
            )}
            {invitations.map((invitation) => (
              <TableRow key={invitation.invitation_code}>
                <TableCell>{invitation.scopes.split(' ').map(scope => SCOPE_MAPPING[scope]).join(', ')}</TableCell>
                <TableCell>{invitation.email ? invitation.email : ''}</TableCell>
                <TableCell>{invitation.claimed ? 'Yes' : 'No'}</TableCell>
                <TableCell>{invitation.revoked ? 'Yes' : 'No'}</TableCell>
                <TableCell>{invitation.created_at}</TableCell>
                <TableCell>
                    <CopyToClipboard content={invitation.link} copiedMessage={'Link copied to clipboard!'} />
                    <Button onClick={() => this.handleRevoke(invitation)}>{invitation.revoked ? 'Enable' : 'Revoke'}</Button>
                    <Button onClick={() => this.handleDelete(invitation)}>{'Delete'}</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

    );
  }
}

const mapStateToProps = ({invitations, auth}: ReduxState) => {
  return {
    inviteLink: invitations.generatedInviteLink,
    loading: invitations.loadingInvites,
    invitations: invitations.items,
    userScopes: auth.user?.scope ?? '',
  };
}

export default connect(mapStateToProps, {getInvitations, generateInviteLink, enablePlatformInvitation, revokePlatformInvitation, deletePlatformInvitation, addError})(PlatformInvitationComponent as any);