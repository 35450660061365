import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, TextField, Typography, Paper, Modal, FormControlLabel, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getUsers, enableUser, disableUser, updateUserPermissions } from '../../actions/admin/users'; // Adjust the import paths as needed
import { ConfirmationDialog } from '../common/Modal';
import { addError } from '../../actions/notifications';
import { ReduxState } from '../../reducers';
import { User } from '../../api';


const SCOPE_MAPPING: Record<string, string> = {
  'dinosync/root': 'Root',
  'dinosync/admin': 'Admin',
  'dinosync/alderon': 'Alderon Connection',
  'dinosync/uber-admin': 'Uber Admin',
  'user': 'User',
};

interface UsersProps {
  getUsers(): void;
  enableUser: (uuid: string) => void;
  disableUser: (uuid: string) => void;
  updateUserPermissions: (uuid: string, addedPermissions: string[], removedPermissions: string[]) => void;
  addError: (error: string) => void;
  users: User[]
  userScopes: string;
}

interface CurrentUser extends User {
  originalScope: string;
}

interface UsersState {
  permissionUpdatesModalOpen: boolean;
  confirmPermissionUpdatesOpen: boolean;
  isDisableConfirmationOpen: boolean;
  currentUser: CurrentUser | null;
  removedPermissions: string[];
  addedPermissions: string[];
  searchTerm: string;
  users: User[];
}

class UsersComponent extends Component<UsersProps, UsersState> {
  state: UsersState = {
    isDisableConfirmationOpen: false,
    permissionUpdatesModalOpen: false,
    confirmPermissionUpdatesOpen: false,
    currentUser: null,
    removedPermissions: [],
    addedPermissions: [],
    users: [],
    searchTerm: ''
  };

  componentDidMount(): void {
    this.props.getUsers();
  }

  componentDidUpdate(prevProps: Readonly<UsersProps>, prevState: Readonly<UsersState>, snapshot?: any): void {
    if (prevProps.users !== this.props.users) {
      this.filterUsers(this.state.searchTerm);
    }
  }

  filterUsers = (searchTerm: string) => {
    const { users } = this.props;
    if (searchTerm === '') {
      this.setState({ users });
      return;
    }
    const filteredUsers = users.filter((user) => user.name.toLowerCase().includes(searchTerm.toLowerCase()) || user.email.toLowerCase().includes(searchTerm.toLowerCase()));
    this.setState({ users: filteredUsers });
  }

  onShowConfirmDisableUser = (user: User) => {
    if (user.disabled) {
      this.props.enableUser(user.uuid);

      return;
    }
    this.setState({ isDisableConfirmationOpen: true, currentUser: {...user, originalScope: user.scope} });
  };

  onConfirmedDisableUser = () => {
    const { currentUser } = this.state;
    if (currentUser !== null) {
      this.props.disableUser(currentUser.uuid);
      this.onCloseConfirmDisable();
    }
  };

  onCloseConfirmDisable = () => this.setState({ isDisableConfirmationOpen: false, currentUser: null, removedPermissions: [], addedPermissions: [] });

  onShowConfirmUpdates = () => {
    const { currentUser, removedPermissions, addedPermissions } = this.state;
    if (currentUser === null) {
      return;
    }
    if (removedPermissions.length > 0 || addedPermissions.length > 0) {
      this.setState({ confirmPermissionUpdatesOpen: true });
    } else {
      this.onClosePermissionUpdates();
    }
  }

  onCloseConfirmUpdates = () => {
    this.setState({ confirmPermissionUpdatesOpen: false });
  }

  onClosePermissionUpdates = () => {
    this.setState({ permissionUpdatesModalOpen: false, currentUser: null, removedPermissions: [], addedPermissions: [] });
  }

  onShowPermissionsModal = (user: User) => {
    this.setState({ permissionUpdatesModalOpen: true, currentUser: {...user, originalScope: user.scope} });
  }

  onConfirmedUpdates = () => {
    const { currentUser, removedPermissions, addedPermissions } = this.state;
    if (currentUser === null) {
      return;
    }
    const { uuid } = currentUser;
    this.props.updateUserPermissions(uuid, addedPermissions, removedPermissions);

    this.onCloseConfirmUpdates();
    this.onClosePermissionUpdates();
  }

  onUberAdminChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { currentUser } = this.state;
    if (currentUser === null) {
      return;
    }
    const checked = event.target.checked;
    const userHadUberAdmin = currentUser.originalScope?.includes('dinosync/uber-admin');
    let scope = `${currentUser.scope.replace('dinosync/uber-admin', '')}${checked ? ' dinosync/uber-admin' : ''}`;
    this.setState({ currentUser: { ...currentUser, scope } });
    if (checked && !userHadUberAdmin) {
      scope = `${scope.replace('dinosync/admin', '').replace('dinosync/alderon', '')} dinosync/admin dinosync/alderon`;
      this.setState({ addedPermissions: [...this.state.addedPermissions, 'dinosync/uber-admin', 'dinosync/admin', 'dinosync/alderon'] });
    }

    if (!checked && userHadUberAdmin) {
      this.setState({ removedPermissions: [...this.state.removedPermissions, 'dinosync/uber-admin'] });
    }
  }

  onAdminChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { currentUser } = this.state;
    if (currentUser === null) {
      return;
    }
    const checked = event.target.checked;
    if (!checked && currentUser.scope.includes('dinosync/uber-admin')) {
      this.props.addError('Uber Admins will always have Admin permissions, to remove the Admin Permission remove Uber Admin first.');
      return;
    }
    const userHadAdmin = currentUser.originalScope?.includes('dinosync/admin');
    let scope = `${currentUser.scope.replace('dinosync/admin', '')}${checked ? ' dinosync/admin dinosync/alderon' : ''}`;
    
    this.setState({ currentUser: { ...currentUser, scope } });
    if (checked && !userHadAdmin) {
      scope = `${scope.replace('dinosync/alderon', '')} dinosync/alderon`;
      this.setState({ addedPermissions: [...this.state.addedPermissions, 'dinosync/admin', 'dinosync/alderon'] });
    }

    if (!checked && userHadAdmin) {
      this.setState({ removedPermissions: [...this.state.removedPermissions, 'dinosync/admin'] });
    }
  }

  onAlderonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { currentUser } = this.state;
    if (currentUser === null) {
      return;
    }
    const checked = event.target.checked;
    if (!checked && (currentUser.scope.includes('dinosync/uber-admin') || currentUser.scope.includes('dinosync/admin'))) {
      this.props.addError('Admins will always have Alderon Connection permission, to remove the Alderon Connection remove the Admin permissions first.');

      return;
    }
    const userHadAlderon = currentUser.originalScope?.includes('dinosync/alderon');
    const scope = `${currentUser.scope.replace('dinosync/alderon', '')}${checked ? ' dinosync/alderon' : ''}`;

    this.setState({ currentUser: { ...currentUser, scope } });
    if (checked && !userHadAlderon) {
      this.setState({ addedPermissions: [...this.state.addedPermissions, 'dinosync/alderon'] });
    }

    if (!checked && userHadAlderon) {
      this.setState({ removedPermissions: [...this.state.removedPermissions, 'dinosync/alderon'] });
    }
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    this.setState({ searchTerm });
    this.filterUsers(searchTerm);
  }

  render() {
    const { isDisableConfirmationOpen, permissionUpdatesModalOpen, currentUser, confirmPermissionUpdatesOpen, searchTerm, users } = this.state;
    const { userScopes } = this.props;
    const canUpdateUberAdmin = userScopes.includes('dinosync/root');
    const canUpdateAdmin = userScopes.includes('dinosync/root') || userScopes.includes('dinosync/uber-admin');
    
    return (
      <Box>
        <ConfirmationDialog
          id="confirm-disable"
          title="Disable User"
          visible={isDisableConfirmationOpen}
          okButtonText='Disable'
          cancelButtonText='Cancel'
          onConfirmed={this.onConfirmedDisableUser}
          onClose={this.onCloseConfirmDisable}>
          <Box>
            <Typography>Are you sure you want to disable this user?</Typography>
          </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          id="confirm-updates"
          title="Permission Updates"
          visible={confirmPermissionUpdatesOpen}
          okButtonText='Update'
          cancelButtonText='Cancel'
          onConfirmed={this.onConfirmedUpdates}
          onClose={this.onCloseConfirmUpdates}>
          <Box>
            <Typography>Are you sure you want to save these changes?</Typography>
          </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          id="update-permissions"
          title="Permission Updates"
          okButtonText='OK'
          cancelButtonText='Cancel'
          visible={permissionUpdatesModalOpen}
          onConfirmed={this.onShowConfirmUpdates}
          onClose={this.onClosePermissionUpdates}>
          <Box>
            <Typography variant="h6">Permissions</Typography>
            <FormControlLabel control={<Checkbox checked={currentUser?.scope?.includes('dinosync/uber-admin')} onChange={this.onUberAdminChange} disabled={!canUpdateUberAdmin} />} label="Uber Admin" />
            <FormControlLabel control={<Checkbox checked={currentUser?.scope?.includes('dinosync/admin')} onChange={this.onAdminChange} disabled={!canUpdateAdmin} />} label="Admin" />
            <FormControlLabel control={<Checkbox checked={currentUser?.scope?.includes('dinosync/alderon')} onChange={this.onAlderonChange} />} label="Alderon Connection" />
          </Box>
        </ConfirmationDialog>
        <Typography variant="h5" sx={{ mb: 2 }}>Platform Users</Typography>
        <TextField
          fullWidth
          label="Search Users"
          variant="outlined"
          margin="normal"
          onChange={this.handleSearchChange}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Role(s)</TableCell>
                <TableCell>Display Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Disabled</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
              </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.scope.split(' ').map(scope => SCOPE_MAPPING[scope]).join(', ')}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.disabled ? 'Yes' : 'No'}</TableCell>
                <TableCell>{user.created_at}</TableCell>
                <TableCell>
                    {!user.disabled && <Button onClick={() => this.onShowPermissionsModal(user)}>{'Permissions'}</Button>}
                    <Button onClick={() => this.onShowConfirmDisableUser(user)}>{user.disabled ? 'Enable' : 'Disable'}</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

    );
  }
}

const mapStateToProps = ({users, auth}: ReduxState) => {
  return {
    loading: users.loadingUsers,
    users: users.items,
    userScopes: auth.user?.scope ?? '',
  };
}

export default connect(mapStateToProps, {getUsers, enableUser, disableUser, addError, updateUserPermissions})(UsersComponent as any);