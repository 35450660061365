import React from 'react';
import { Box, SxProps, Tooltip } from '@mui/material';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';

export const Flag = ({ sx, code, country }: { sx?: SxProps, code: FlagIconCode, country: string }) => {
    return (
        <Tooltip title={country} placement="top">
            <Box sx={sx}>
            <FlagIcon code={code} height={15} width={25} />
            </Box>
      </Tooltip>
    )
}