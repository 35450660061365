import { GetStateFunction, DispatchFunction } from "../reducers";
import { enableInvitation, generateInviteLink as generatedInviteLinkAPI, getInvitations as getInvitationsAPI, revokeInvitation, deleteInvitation } from "../api/platformInvites";
import { addError } from "./notifications";
import { INVITE_LINK, PLATFORM_INVITES_LOADING, PLATFORM_INVITES_UPDATE } from "./types";
import { PlatformInvitation } from "../api/platformInvites.types";

export const generateInviteLink = (scopes: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        const inviteLink = await generatedInviteLinkAPI(scopes);

        dispatch({ type: INVITE_LINK, payload: inviteLink });
        
        dispatch(getInvitations() as any);
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while generating the invitation link. Please try again.'));
        }
    }
}

export const getInvitations = ()  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: PLATFORM_INVITES_LOADING, payload: true });
        const items = await getInvitationsAPI();

        dispatch({ type: PLATFORM_INVITES_UPDATE, payload: items });
        dispatch({ type: PLATFORM_INVITES_LOADING, payload: false });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while generating the invitation link. Please try again.'));
        }
        
        dispatch({ type: PLATFORM_INVITES_LOADING, payload: false });
    }
}

export const revokePlatformInvitation = (code: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await revokeInvitation(code);
        
        const { invitations } = getState();
        const { items: stateItems } = invitations;
        const updatedItems = stateItems.map((item: PlatformInvitation) => {
            if (item.invitation_code === code) {
                return {
                    ...item,
                    revoked: true,
                }
            }

            return item;
        });

        dispatch({ type: PLATFORM_INVITES_UPDATE, payload: updatedItems });
    } catch (e: any) {
        console.error(e);
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while revoking the invitation, please try again.'));
        }
    }
}

export const enablePlatformInvitation = (code: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await enableInvitation(code);
        
        const { invitations } = getState();
        const { items: stateItems } = invitations;
        const updatedItems = stateItems.map((item: PlatformInvitation) => {
            if (item.invitation_code === code) {
                return {
                    ...item,
                    revoked: false,
                }
            }

            return item;
        });

        dispatch({ type: PLATFORM_INVITES_UPDATE, payload: updatedItems });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            console.error(e);
            dispatch(addError('An error occurred while enabling the invitation, please try again.'));
        }
    }
}

export const deletePlatformInvitation = (code: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await deleteInvitation(code);
        
        const { invitations } = getState();
        const { items: stateItems } = invitations;
        const updatedItems = stateItems.filter((item: PlatformInvitation) => {
            return item.invitation_code !== code;
        });

        dispatch({ type: PLATFORM_INVITES_UPDATE, payload: updatedItems });
    } catch (e: any) {
        console.error(e);
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while deleting the invitation, please try again.'));
        }
    }
}