export class GenericError extends Error {
    userFriendly: boolean = true;
    constructor(message: string) {
        super(message)
    }
}

export class FriendlyApiError extends GenericError {
    error: string
    error_description: string
    message: string

    constructor({ error, error_description, message }: FriendlyApiError) {
        super(message)
        this.error = error;
        this.error_description = error_description;
        this.message = message;
    }
  }
  