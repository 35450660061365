import React from 'react';
import { withAuthProps } from '../../common/hooks/withAuth';
import { ReduxState } from '../../reducers';
import { connect } from 'react-redux';
import { loadSocialMap, loadServerPlayers } from '../../actions/social';
import { AlderonFriend, SocialMap } from '../../api/social.types';
import { SelectOption } from '../common/Select';
import { SocialComponent } from './SocialComponent';
import { ServerInfo } from '../../api/servers.types';
import { PlayerGroup } from '../../api/groups.types';
import { getPlayerGroups } from '../../actions/groups';

interface ReduxStateProps {
  map: SocialMap
  friends: SelectOption<AlderonFriend>[]
  servers: { [key: string]: ServerInfo }
  groups: PlayerGroup[]
  loadingSocialMap: boolean
}

interface ReduxActionProps {
  loadSocialMap: () => void;
  loadServerPlayers: (serverKey: string) => void;
  getPlayerGroups: () => void;
}

interface ComponentProps extends withAuthProps {}

interface SocialState {}

export type SocialProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class SocialMapComponent extends React.Component<SocialProps, SocialState> {

  componentDidMount(): void {
    this.props.loadSocialMap()
    this.props.getPlayerGroups()
  }

  onPullServerPlayers = (serverKey: string) => {
    this.props.loadServerPlayers(serverKey)
  }

  render = () => {
    const { map, friends, servers, loadingSocialMap } = this.props;
    return (
        <SocialComponent
          loadingSocialMap={loadingSocialMap}
          map={map}
          friends={friends}
          servers={servers}
          onRefresh={this.props.loadSocialMap}
          onPullServerPlayers={this.onPullServerPlayers}
          groups={this.props.groups}
        />
    );
  }
}


const mapStateToProps = ({ social, groups }: ReduxState) => {
  return {
    map: social.map,
    friends: social.friends,
    servers: social.servers,
    loadingSocialMap: social.loadingSocialMap,
    groups: groups.groups,
  }
};

export const SocialPage = connect(mapStateToProps, {loadSocialMap, loadServerPlayers, getPlayerGroups})(SocialMapComponent);