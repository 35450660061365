/*eslint import/namespace: [2, { allowComputed: true }]*/
import React from 'react';
import axios from 'axios'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { Home } from './components/home';
import { LoginComponent } from './components/auth/Login';
import { Header } from './components/nav/Header';
import { Box } from '@mui/material';
import { RequiresAuthentication } from './components/common/RequiresAuthentication';
import { SocialPage, UserServer } from './components/social';
import UserSync from './components/misc/UserSync';
import { RegisterComponent } from './components/auth/Register';
import NotificationBar from './components/common/NotificationBar';
import { useAuth } from './common/hooks/useAuth';
import { AlderonConnection } from './components/alderon';
import ForgotPassword from './components/forgot-password/ForgotPassword';
import ResetPassword from './components/forgot-password/ResetPassword';
import { AccountComponent } from './components/account';
import AdminPageComponent from './components/admin/AdminPage';
import NotFound from './components/common/Errors/NotFound';
import { GroupsComponent } from './components/groups';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const App: React.FunctionComponent = () => {
  const { user } = useAuth()
  return (
      <Router>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
              <NotificationBar />
              <Header />
              <UserSync />
              <Box>
                <Routes>
                  <Route path="/login" element={<LoginComponent />} />
                  <Route path="/register" element={<RegisterComponent />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/" element={<RequiresAuthentication><Home /></RequiresAuthentication>} />
                  <Route path="/groups" element={<RequiresAuthentication><GroupsComponent /></RequiresAuthentication>} />
                  <Route path="/account" element={<RequiresAuthentication><AccountComponent /></RequiresAuthentication>} />
                  <Route path="/social" element={<RequiresAuthentication><SocialPage /></RequiresAuthentication>} />
                  <Route path="/server" element={<RequiresAuthentication><UserServer /></RequiresAuthentication>} />
                  {user?.scope?.includes('dinosync/alderon') && <Route path="/alderon/connection" element={<RequiresAuthentication><AlderonConnection /></RequiresAuthentication>} />}
                  {user?.scope?.includes('dinosync/admin') && <Route path="/admin/*" element={<RequiresAuthentication><AdminPageComponent /></RequiresAuthentication>} />}
                  <Route path='*' element={<NotFound />} />
                </Routes>
              </Box>
            </Box>
      </Router>
  );
};
