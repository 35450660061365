import { DispatchFunction, GetStateFunction } from "../reducers";
import { addError, addSuccess } from "./notifications";
import { 
    getPlayerGroups  as getPlayerGroupsApi,
    createPlayerGroup as createPlayerGroupApi,
    deletePlayerGroupByGroupId as deletePlayerGroupByGroupIdApi,
    updatePlayerGroupById as updatePlayerGroupByIdApi,
    addPlayerToPlayerGroupById as addPlayerToPlayerGroupByIdApi,
    removePlayerToPlayerGroupById as removePlayerToPlayerGroupByIdApi,
    getGroupInvitationCode,
    joinGroupByInvitation as joinGroupByInvitationApi,
    unjoinGroupByUuid as unjoinGroupByUuidApi,
    getTargetPlayers as getTargetPlayersApi,
} from "../api/groups";
import { 
    addPlayerTargets as addPlayerTargetsApi,
} from "../api/player-targets";
import { GROUPS_PROP_UPDATE } from "./types";
import { PlayerGroup } from "../api/groups.types";
import { copyToClipboard } from "../common/utils/utils";
import { AlderonFriendType } from "../api/social.types";
import { addPlayerTarget, removePlayerTarget } from "../api/player-targets";


export const getPlayerGroups = () => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: GROUPS_PROP_UPDATE, payload: { loading: true } })
        const items = await getPlayerGroupsApi();
        if (!items) {
            throw new Error('Failed to retrieve player groups');
        }
        const groups = items.map(({ players, ...item}: PlayerGroup) => (item)).sort((a, b) => a.name.localeCompare(b.name));
        const players = items.reduce((acc: any, { players, ...item }: PlayerGroup) => {
            acc[item.uuid] = players;
            return acc;
        }, {});
        dispatch({ type: GROUPS_PROP_UPDATE, payload: {groups, players, loading: false, groupsLoaded: true } })
    } catch(e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while retrieve player groups. Please try again.'));
        }
    }
}


export const createPlayerGroup = (name: string, type: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        const result = await createPlayerGroupApi(name, type);
        if (!result) {
            throw new Error('Failed to create player groups');
        }
        const { players, ...item} = result;

        const { groups} = getState();
        const { groups: currentGroups, players: currentPlayers  } = groups;
        const updatedGroups = [, {...item, owner: true }, ...currentGroups];
        const updatedPlayers = { ...currentPlayers, [item.uuid]: players };

        dispatch({ type: GROUPS_PROP_UPDATE, payload: { groups: updatedGroups, players: updatedPlayers } })
    } catch(e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while creating player group. Please try again.'));
        }
    }
}

export const deletePlayerGroupByGroupId = (groupId: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
       const { success } = await deletePlayerGroupByGroupIdApi(groupId);
       if (success) {
           dispatch(addSuccess('Successfully deleted player group'));
       }
       const { groups } = getState();
       const { groups: currentGroups } = groups;
       const updatedGroups = currentGroups.filter(({ uuid }) => uuid !== groupId);
       dispatch({ type: GROUPS_PROP_UPDATE, payload: { groups: updatedGroups } });
    } catch(e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while removing group. Please try again.'));
        }
    }
}

export const updatePlayerGroupById = (groupId: string, update: {name: string, type: string}) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
       const item = await updatePlayerGroupByIdApi(groupId, update);
       if (item) {
           dispatch(addSuccess('Successfully deleted player group'));
       }
       const { groups } = getState();
       const { groups: currentGroups } = groups;
       const updatedGroups = currentGroups.map((group) => {
              if (group.uuid === groupId) {
                return item;
              }
              return group;
         }
    );
       dispatch({ type: GROUPS_PROP_UPDATE, payload: { groups: updatedGroups } });
    } catch(e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while updating group. Please try again.'));
        }
    }
}

export const addPlayerToPlayerGroupById = (groupId: string, agid: string | number, name: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
       const item = await addPlayerToPlayerGroupByIdApi(groupId, agid, name);
       if (item) {
           dispatch(addSuccess('Successfully added player to group'));
       }
       const { groups } = getState();
       const { players: currentPlayers } = groups;
       const groupPlayers = currentPlayers[groupId] || [];
       const updatedPlayers = [...groupPlayers, item];
       const updatedGroupPlayers = { ...currentPlayers, [groupId]: updatedPlayers };
       dispatch({ type: GROUPS_PROP_UPDATE, payload: { players: updatedGroupPlayers } });
    } catch(e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while adding player to group. Please try again.'));
        }
    }
}


export const removePlayerToPlayerGroupById = (groupId: string, playerId: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
       const { success } = await removePlayerToPlayerGroupByIdApi(groupId, playerId);
       if (success) {
           dispatch(addSuccess('Successfully added player to group'));
       }
       const { groups } = getState();
       const { players: currentPlayers } = groups;
       const groupPlayers = currentPlayers[groupId] || [];
       const updatedPlayers = groupPlayers.filter(({ uuid }) => uuid !== playerId);
       const updatedGroupPlayers = { ...currentPlayers, [groupId]: updatedPlayers };
       dispatch({ type: GROUPS_PROP_UPDATE, payload: { players: updatedGroupPlayers } });
    } catch(e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while removing player from group. Please try again.'));
        }
    }
}

export const getGroupInvitationLink = (groupId: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
       const invitationCode = await getGroupInvitationCode(groupId);
       if (!invitationCode) {
        dispatch(addError('An error occurred while getting invitation link, please try again.'));
       }
       const invitationLink = `${window.location.origin}/groups?invitation=${invitationCode}`;
       const { groups } = getState();
       const { invitations } = groups;
       const updatedInvitations = { [groupId]: invitationLink, ...invitations };

       copyToClipboard(invitationLink);
       dispatch(addSuccess('Invitation link retrieved and copied to clipboard'));


       dispatch({ type: GROUPS_PROP_UPDATE, payload: { invitations: updatedInvitations } });
    } catch(e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while retriving invitation code. Please try again.'));
        }
    }
}

export const joinGroupByInvitation = (invitation_uuid: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        const result = await joinGroupByInvitationApi(invitation_uuid);
        if (result) {
            dispatch(addSuccess('Successfully added group to your list'));
        }
        const { players, ...item} = result;

        const { groups} = getState();
        const { groups: currentGroups, players: currentPlayers  } = groups;
        const updatedGroups = [...currentGroups, item];
        const updatedPlayers = { ...currentPlayers, [item.uuid]: players };

        dispatch({ type: GROUPS_PROP_UPDATE, payload: { groups: updatedGroups, players: updatedPlayers } })
    } catch(e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while adding group to your list. Please try again.'));
        }
    }
}

export const unjoinGroupByUuid = (groupUuid: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        const success = await unjoinGroupByUuidApi(groupUuid);
        if (success) {
            dispatch(addSuccess('Successfully unjoined group'));
        }
        const { groups } = getState();
        const { groups: currentGroups } = groups;
        const updatedGroups = currentGroups.filter(({ uuid }) => uuid !== groupUuid);
        dispatch({ type: GROUPS_PROP_UPDATE, payload: { groups: updatedGroups } });
    } catch(e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while unjoining group. Please try again.'));
        }
    }
}

export const getFriends = () => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: GROUPS_PROP_UPDATE, payload: { loadingFriends: true } });
        const items = await getTargetPlayersApi();
        const friends = items.reduce((acc: any, item: any) => {
            acc[item.agid] = item;
            return acc;
        }, {});

        dispatch({ type: GROUPS_PROP_UPDATE, payload: { friends, loadingFriends: false } });
    } catch(e: any) {
        dispatch({ type: GROUPS_PROP_UPDATE, payload: { loadingFriends: false } });
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while retrieving your tracked players.'));
        }
    }
}


export const trackPlayer = (alderonId: number, type: AlderonFriendType | null) => async (dispatch: DispatchFunction | any, getState: GetStateFunction) => {
    const { groups } = getState();
    const { friends } = groups;
    const updatedFriends = { ...friends, [alderonId]: {
        ...friends[alderonId],
        type,
    } };

    dispatch({ type: GROUPS_PROP_UPDATE, payload: { friends: updatedFriends } });
    try {
        if (type === AlderonFriendType.NONE || type === null) {
            await removePlayerTarget(alderonId.toString())
        } else {
            await addPlayerTarget(alderonId.toString(), type)
        }
    } catch (e) {
        dispatch(addError('An error occurred while updating player status. Please try again.'));
        dispatch({ type: GROUPS_PROP_UPDATE, payload: { friends } });
    }
}



export const addPlayerTargets = (alderonIds: number[], type: AlderonFriendType | null) => async (dispatch: DispatchFunction | any, getState: GetStateFunction) => {
    const { groups } = getState();
    const { friends } = groups;
    const updatedFriends = Object.keys(friends).reduce((acc: any, key: string) => {
        const id = parseInt(key);
        if (alderonIds.includes(id)) {
            acc[id] = {
                ...friends[id],
                type: friends[id].friends ? friends[id].type : type,
            }
        } else {
            acc[id] = friends[id];
        }
        return acc;
    }, {});

    for (const id of alderonIds) {
        if (!updatedFriends[id]) {
            updatedFriends[id] = {
                agid: id,
                display_name: '',
                friends: false,
                type,
            }
        }
    }

    dispatch({ type: GROUPS_PROP_UPDATE, payload: { friends: updatedFriends } });
    try {
        await addPlayerTargetsApi(alderonIds, type)
    } catch (e) {
        dispatch(addError('An error occurred while updating player statuses. Please try again.'));
        dispatch({ type: GROUPS_PROP_UPDATE, payload: { friends } });
    }
}