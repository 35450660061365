import React from 'react'
import { useAuth } from './useAuth';

export const withRegister = (Component: any) => {
  const Wrapper = (props: any) => {
    const { authenticated, initialized, register } = useAuth();
    return (
      <Component
        authenticated={authenticated}
        authInitialized={initialized}
        register={register}
        {...props}
        />
    );
  };
  
  return Wrapper;
};