import { NotificationItem } from "../components/common/types";
import { NOTIFICATIONS_UPDATED, USER_UPDATE } from "../actions/types";

export interface NotificationsState {
    items: NotificationItem[];
}

const InitialState: NotificationsState = {
    items: [],
};

export const NotificationReducer = (state: NotificationsState = InitialState, action: any) => {
    switch (action.type) {
        case NOTIFICATIONS_UPDATED:
            return {
                ...state,
                items: action.payload,
            };
    }
    return state;
}

export default NotificationReducer;