import React from 'react';
import { connect } from 'react-redux';
import { Box, TextField, Typography } from '@mui/material';
import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { ReduxState } from '../../reducers';
import { Page } from '../common/Page';
import { GROUP_TYPES, PlayerGroup } from '../../api/groups.types';
import { PlayerGroupUser } from '../../api/groups.types';
import { addPlayerToPlayerGroupById, createPlayerGroup, deletePlayerGroupByGroupId, getGroupInvitationLink, getPlayerGroups, removePlayerToPlayerGroupById, updatePlayerGroupById, joinGroupByInvitation, unjoinGroupByUuid, getFriends, trackPlayer, addPlayerTargets } from '../../actions/groups';
import { GroupCard } from './GroupCard';
import { Button } from '@mui/material';
import { ConfirmationDialog } from '../common/Modal';
import { Select, SelectOption } from '../common/Select';
import { withRouter, WithRouterProps } from '../../common/hooks/withParams';
import { getGroupDetailsFromInvitation } from '../../api/groups';
import { AlderonFriendSkinny, AlderonFriendType } from '../../api/social.types';

interface ReduxStateProps {
  items: PlayerGroup[];
  targets: number[];
  players: {
    [key: string]: PlayerGroupUser[]
  };
  friends: {
    [key: number]: AlderonFriendSkinny
  };
  loadingFriends: boolean;
  groupsLoaded: boolean;
}

interface ReduxActionProps {
  getPlayerGroups: () => void;
  createPlayerGroup: (name: string, type: string) => void;
  deletePlayerGroupByGroupId: (groupId: string) => void;
  addPlayerToPlayerGroupById: (groupId: string, agid: string | number, name: string) => void;
  removePlayerToPlayerGroupById: (groupId: string, playerId: string) => void;
  updatePlayerGroupById: (groupId: string, update: { name: string, type: string }) => void;
  getGroupInvitationLink: (groupId: string) => void;
  unjoinGroupByUuid: (groupId: string) => void;
  joinGroupByInvitation: (invitation: string) => void;
  getFriends: () => void;
  trackPlayer: (agid: number, type: AlderonFriendType | null) => void;
  addPlayerTargets: (ids: number[] | string[] | (number | string)[], type: AlderonFriendType | null) => void;
}

interface ComponentProps extends WithRouterProps {}

interface GroupsState {
  addModalVisible: boolean;
  groupName: string;
  groupType: SelectOption | null;
  showJoinGroupModal: boolean;
  joinGroup: { uuid: string, name: string, owner: string } | null;
  invitation?: string;
}

export type GroupsProps = ComponentProps & ReduxStateProps & ReduxActionProps & withAuthProps;

class Groups extends React.Component<GroupsProps, GroupsState> {

  constructor(props: GroupsProps) {
    super(props);
    this.state = {
      addModalVisible: false,
      groupName: '',
      groupType: null,
      showJoinGroupModal: false,
      joinGroup: null,
    }
  }

  componentDidMount(): void {
    if (!this.props.groupsLoaded) {
      this.props.getPlayerGroups();
    }
    this.props.getFriends();
    const searchParams = new URLSearchParams(this.props.location.search);
    const invitation = searchParams.get('invitation');

    if (invitation) {
      this.processInvitation(invitation);
    }
  }

  processInvitation = async (invitation: string) => {
    try {
      const group = await getGroupDetailsFromInvitation(invitation);
      this.props.navigate('/groups');

      if (!group) {
        return;
      }

      this.setState({ showJoinGroupModal: true, joinGroup: group, invitation});
    } catch (e)  {
      this.props.navigate('/groups');
    }
  }

  onDeleteGroup = (group: PlayerGroup) => {
    this.props.deletePlayerGroupByGroupId(group.uuid)
  }

  onEditGroup = (group: PlayerGroup) => {
    this.props.updatePlayerGroupById(group.uuid, { name: group.name, type: group.type })
  }

  onAddPlayer = (group: PlayerGroup, agid: string | number, name: string) => {
    this.props.addPlayerToPlayerGroupById(group.uuid, agid, name)
  }

  onRemovePlayer = (group: PlayerGroup, player: PlayerGroupUser) => {
    this.props.removePlayerToPlayerGroupById(group.uuid, player.uuid)
  }

  onCloseAddGroup = () => {
    this.setState({ addModalVisible: false, groupName: '', groupType: null });
  }

  onOpenAddGroup = () => {
    this.setState({ addModalVisible: true });
  }

  onConfirmAddGroup = () => {
    this.onCloseAddGroup();
    const { groupName, groupType } = this.state;
    this.props.createPlayerGroup(groupName, groupType?.value ?? '');
  }

  onShareGroup = (group: PlayerGroup) => {
    this.props.getGroupInvitationLink(group.uuid)
  }

  onGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ groupName: e.target.value });
  }

  onGroupTypeChange = (type: SelectOption) => {
    this.setState({ groupType: type });
  }

  onCloseAddGroupFromInvitation = () => {
    this.setState({ showJoinGroupModal: false, joinGroup: null, invitation: undefined });
  }

  onConfirmAddGroupFromInvitation = () => {
    this.setState({ showJoinGroupModal: false, joinGroup: null });
    this.props.joinGroupByInvitation(this.state.invitation ?? '');
  }

  onUnjoinGroup = (group: PlayerGroup) => {
    this.props.unjoinGroupByUuid(group.uuid);
  }

  onChangeFriendType = (player: PlayerGroupUser, type: AlderonFriendType | null) => {
    this.props.trackPlayer(player.agid, type);
  }

  onTrackAllPlayers = (group: PlayerGroup, players: PlayerGroupUser[]) => {
    const ids = players.map((player) => player.agid);
    this.props.addPlayerTargets(ids, group.type as any);
  }

  onUntrackAllPlayers = (group: PlayerGroup, players: PlayerGroupUser[]) => {
    const ids = players.map((player) => player.agid);
    this.props.addPlayerTargets(ids, AlderonFriendType.NONE);
  }

  render = () => {
    const { items, players, friends, loadingFriends } = this.props;
    const { addModalVisible, groupName, groupType, showJoinGroupModal } = this.state;
    
    return (
      <Page>
          <Box sx={{ p: 5 }}>
          <ConfirmationDialog
              id={`add-group`}
              title={`Add Group`}
              visible={addModalVisible}
              onClose={this.onCloseAddGroup}
              onConfirmed={this.onConfirmAddGroup}
              contentSx={{
                overflow: 'visible'
              }}
            >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              marginBottom: '2rem',
            }}>
            <TextField
              label="Name"
              value={groupName}
              onChange={this.onGroupNameChange}
            />
              <Box sx={{
                display: 'flex',
                alignItems: {
                  xxs: 'flex-start',
                  sm: 'center',
                },
                flexDirection: {
                  xxs: 'column',
                  sm: 'row',
                },
                overflow: 'visible'
              }}>
              <Select sx={{ maxWidth: '20rem', width: '15rem' }} label={'Group Type'} value={groupType} items={GROUP_TYPES} onItemSelected={this.onGroupTypeChange} />
              </Box>
            </Box>
          </ConfirmationDialog>

          <ConfirmationDialog
              id={`add-group-from-invitation`}
              title={`${this.state.joinGroup?.name} ${this.state.joinGroup?.owner ? `(By ${this.state.joinGroup?.owner})` : '' }`}
              visible={showJoinGroupModal}
              onClose={this.onCloseAddGroupFromInvitation}
              onConfirmed={this.onConfirmAddGroupFromInvitation}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="body1" color="text.primary">
                  Do you want to add this group to your Groups page?
                </Typography>
              </Box>
          </ConfirmationDialog>
          <Box sx={{ display: 'flex', mb: '2rem', minWidth: 275, maxWidth: '40rem', justifyContent: 'flex-end' }}>
            <Button 
                sx={{
                  mt: 2,
                  py: 2,
                }} onClick={this.onOpenAddGroup}>Create Group</Button>
          </Box>
            {items.map((group: PlayerGroup) => (
                <GroupCard 
                  key={group.uuid}
                  group={group}
                  players={players[group.uuid]}
                  onDeleteGroup={this.onDeleteGroup}
                  onEditGroup={this.onEditGroup}
                  onAddPlayer={this.onAddPlayer}
                  onRemovePlayer={this.onRemovePlayer}
                  onShareGroup={this.onShareGroup}
                  onUnjoinGroup={this.onUnjoinGroup}
                  onChangeFriendType={this.onChangeFriendType}
                  onTrackAllPlayers={this.onTrackAllPlayers}
                  onUntrackAllPlayers={this.onUntrackAllPlayers}
                  loadingFriends={loadingFriends}
                  friends={friends}
                />
            ))}
          </Box>
      </Page>
    )
  }
}


const mapStateToProps = ({ groups }: ReduxState) => {
  const { groups: items, players, friends, loadingFriends, groupsLoaded } = groups;
  
  return {
    items,
    players,
    friends,
    loadingFriends,
    groupsLoaded,
  }
};

export const GroupsComponent = withRouter(withAuth(connect(mapStateToProps, { getFriends, trackPlayer, createPlayerGroup, getPlayerGroups, updatePlayerGroupById, deletePlayerGroupByGroupId, addPlayerToPlayerGroupById, removePlayerToPlayerGroupById, getGroupInvitationLink, joinGroupByInvitation, unjoinGroupByUuid, addPlayerTargets })(Groups as any)));