export const friendlyTimeDifference = (date1: string, date2: string): string => {
    const moment = require('moment');

    let dateA = moment(date1);
    let dateB = moment(date2);

    const duration = moment.duration(dateB.diff(dateA));

    const hours = Math.abs(duration.hours());
    const minutes = Math.abs(duration.minutes());
    const seconds =Math.abs( duration.seconds());

    const parts = [
    hours > 0 ? `${hours} hour${hours !== 1 ? 's' : ''}` : '',
    minutes > 0 ? `${minutes} min${minutes !== 1 ? 's' : ''}` : '',
    seconds > 0 && minutes === 0 && hours === 0 ? `${seconds} sec${seconds !== 1 ? 's' : ''}` : ''
    ].filter(part => part !== '');  // Filter out empty strings

    const friendlyDescription = parts.join(' ');

    return friendlyDescription;
}

//https://stackoverflow.com/a/65996386/1286412
export const copyToClipboard = (textToCopy: string) => {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res(true) : rej();
            textArea.remove();
        });
    }
  }

  
export const formatAgid = (agid: number) => {
    return agid.toString().padStart(9, '0').replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
}

export const formatAlderonIdInput = (input: string): string => {
    // Remove all non-digits
    const digits = input.replace(/\D/g, '');

    let formattedValue = '';
    for (let i = 0; i < digits.length; i++) {
        if (i === 3 || i === 6) {
            formattedValue += '-';
        }
        formattedValue += digits[i];
    }

    const trimmedValue = formattedValue.length > 11 ? formattedValue.substring(0, 11) : formattedValue

    return trimmedValue;
  }
  