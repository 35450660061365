export const SOCIAL_MAP_UPDATE = 'SOCIAL_MAP_UPDATE';
export const USER_SERVER_UPDATE = 'USER_SERVER_UPDATE';
export const USER_UPDATE = 'USER_UPDATE';
export const SERVER_PLAYERS_UPDATE = 'SERVER_PLAYERS_UPDATE';

export const PULLING_USERS = 'PULLING_USERS';
export const NOTIFICATIONS_UPDATED = 'NOTIFICATIONS_UPDATED';

export const PLATFORM_INVITES_LOADING  = 'PLATFORM_INVITES_LOADING';
export const PLATFORM_INVITES_UPDATE  = 'PLATFORM_INVITES_UPDATE';

export const USERS_UPDATE = 'USERS_UPDATE';
export const USERS_LOADING = 'USERS_LOADING';

export const INVITE_LINK = 'INVITE_LINK';

export const LOADING_GROUPS = 'LOADING_GROUPS';
export const GROUPS_PROP_UPDATE = 'GROUPS_PROP_UPDATE';