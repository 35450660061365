import { combineReducers } from "redux";
import { SocialReducer, SocialState } from "./SocialReducer";
import { AuthReducer, AuthState } from "./AuthReducer";
import { UserServerReducer, UserServerState } from "./UserServerReducer";
import NotificationReducer, { NotificationsState } from "./NotificationsReducer";
import PlatformInvitesReducer, { PlatformInvitesState } from "./PlatformInvitesReducer";
import UsersReducer, { UsersState } from "./UsersReducer";
import GroupsReducer, { GroupsState } from "./GroupsReducer";

export interface ReduxState {
    social: SocialState;
    auth: AuthState;
    userServer: UserServerState;
    notifications: NotificationsState;
    invitations: PlatformInvitesState;
    users: UsersState;
    groups: GroupsState;
}

export type GetStateFunction = () => ReduxState;
export type DispatchFunction = (action: { type: string, payload: any }) => void;

export const reducers: any = combineReducers({
    social: SocialReducer,
    auth: AuthReducer,
    userServer: UserServerReducer,
    notifications: NotificationReducer,
    invitations: PlatformInvitesReducer,
    users: UsersReducer,
    groups: GroupsReducer,
});