import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, TextField, Typography, Paper } from '@mui/material';
import { initiateResetPassword } from '../../actions/user';

interface ForgotPasswordState {
  email: string;
}

interface ForgotPasswordProps {
  initiateResetPassword: (email: string) => void;
}

export class ForgotPassword extends Component<ForgotPasswordProps, ForgotPasswordState> {
  state = {
    email: '',
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
  };

  handleResetPassword = () => {
    const { email } = this.state;
    this.props.initiateResetPassword(email);
  };

  render() {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Paper elevation={3} sx={{ p: 4, width: '100%', maxWidth: 400 }}>
          <Typography variant="h5" gutterBottom>
            Reset Password
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Enter your email address below, and we'll send you instructions to reset your password.
          </Typography>
          <TextField
            label="Email Address"
            name="email"
            type="email"
            variant="outlined"
            fullWidth
            value={this.state.email}
            onChange={this.handleChange}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={this.handleResetPassword} fullWidth>
            Reset
          </Button>
        </Paper>
      </Box>
    );
  }
}

const mapDispatchToProps = {
  initiateResetPassword,
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
