import axios from 'axios';
import { PlayerGroup, PlayerGroupUser } from './groups.types';
import { FriendlyApiError, GenericError } from './types';
import { AlderonFriendSkinny } from './social.types';

const GROUPS_ENDPOINT = '/api/groups'
const FRIENDS_ENDPOINT = '/api/alderon/user/friends?skinny=1'

export const getPlayerGroups = async(): Promise<PlayerGroup[]> => {
    try {
        const { data } = await axios.get(GROUPS_ENDPOINT)
        const { items } = data as any;

        return items as PlayerGroup[];
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Unable to delete group');
    }
}

export const createPlayerGroup = async(name: string, type: string): Promise<PlayerGroup> => {
    try {
        const { data } = await axios.post(GROUPS_ENDPOINT, { name, type })
        const { item } = data as any;

        return item as PlayerGroup;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Unable to create group');
    }
}

export const deletePlayerGroupByGroupId = async(groupId: string): Promise<{ success: boolean }> => {
    try {
        const { data } = await axios.delete(`${GROUPS_ENDPOINT}/${groupId}`)
        const { success } = data as any;

        return  { success }
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Unable to delete group');
    }
}

export const updatePlayerGroupById = async(groupId: string, update: {name: string, type: string}): Promise<PlayerGroup> => {
    try {
        const { data } = await axios.put(`${GROUPS_ENDPOINT}/${groupId}`, update)
        const { item } = data as any;

        return item as PlayerGroup;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Unable to update group name');
    }
}

export const addPlayerToPlayerGroupById = async(groupId: string, agid: string | number, name: string): Promise<PlayerGroupUser> => {
    try {
        const { data } = await axios.post(`${GROUPS_ENDPOINT}/${groupId}/players`, { agid, name })
        const { item } = data;

        return item as PlayerGroupUser;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Unable to add player to group');
    }
}

export const removePlayerToPlayerGroupById = async(groupId: string, playerUuid: string): Promise<{ success: boolean }> => {
    try {
        const { data } = await axios.delete(`${GROUPS_ENDPOINT}/${groupId}/players/${playerUuid}`)
        const { success } = data as any;

        return  { success }
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Unable to remove player from group');
    }
}



export const getGroupInvitationCode = async(groupUuid: string): Promise<PlayerGroup> => {
    try {
        const { data } = await axios.get(`${GROUPS_ENDPOINT}/${groupUuid}/invitation`)
        const { item } = data as any;
        const { invitation_uuid } = item;

        return invitation_uuid;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Unable to get invitation link');
    }
}

export const getGroupDetailsFromInvitation = async(uuid: string): Promise<{ uuid: string, name: string, owner: string } | undefined> => {
    try {
        const { data } = await axios.get(`${GROUPS_ENDPOINT}/invitation/${uuid}`)
        const { item } = data as any;

        return item;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Unable to get invitation link');
    }
}

export const joinGroupByInvitation = async(invitationUuid: string): Promise<PlayerGroup> => {
    try {
        const { data } = await axios.post(`${GROUPS_ENDPOINT}/invitation/${invitationUuid}/accept`)
        const { item } = data as any;
        if (!item) {
            throw new GenericError('Unable to join group');
        }

        return item;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Unable to join group');
    }
}

export const unjoinGroupByUuid = async(groupUuid: string): Promise<boolean> => {
    try {
        const { data } = await axios.post(`${GROUPS_ENDPOINT}/${groupUuid}/unjoin`)
        const { success } = data as any;

        if (!success) {
            throw new GenericError('Unable to unjoin group');
        }

        return success;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Unable to unjoin group');
    }
}

export const getTargetPlayers = async(): Promise<AlderonFriendSkinny[]> => {
    try {
        const { data } = await axios.get(FRIENDS_ENDPOINT)
        const { items } = data as any;

        if (!items || !items.length) {
            throw new GenericError('Unable to retrieve target players');
        }

        return items;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Unable to retrieve target players');
    }
}