import React, { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Snackbar } from '@mui/material';

// Define the component props
interface CopyToClipboardProps {
  content: string;
  copiedMessage?: string
  onCopied?: () => void;
}

const copyToClipboard = async (textToCopy: string) => {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res(true) : rej();
            textArea.remove();
        });
    }
}
const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ content, copiedMessage, onCopied }) => {
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);

  const handleCopy = useCallback(() => {
    copyToClipboard(content).then(() => {
      // Call the onCopied callback if provided
      if (onCopied) {
        onCopied();
      }
      // Show confirmation via snackbar
      setOpenSnackbar(true);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }, [content, onCopied]);

  const handleCloseSnackbar = useCallback(() => {
    setOpenSnackbar(false);
  }, []);

  return (
    <>
      <IconButton onClick={handleCopy} aria-label="copy">
        <ContentCopyIcon sx={{ height: 15, width: 15 }} />
      </IconButton>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={copiedMessage ?? "Content copied to clipboard"}
      />
    </>
  );
};

export default CopyToClipboard;
