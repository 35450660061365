import { Component } from "react";
import { SnackbarCloseReason, Stack } from "@mui/material";
import { connect } from "react-redux";
import { ReduxState } from "../../reducers";
import { NotificationItem } from "./types";
import { Notification } from "./Notification";
import { removeNotification } from "../../actions/notifications";

interface NotificationBarProps {
  items: NotificationItem[]
  removeNotification: (id: string) => void;
}

class NotificationBar extends Component<NotificationBarProps> {
  
    onClose = (id: string) => {
        this.props.removeNotification(id)
    }

    render() {
      const { items } = this.props;
        return (
           <Stack spacing={7} sx={{ maxWidth: 600 }}>
            {items.map(item => {
              return <Notification key={item.id} item={item} onClose={this.onClose} />
            })}
           </Stack>
        );
    }
}

const mapStateToProps = ({ notifications }: ReduxState) => {
  const { items } = notifications;
  
    return {
        items,
    }
}

const ConnectedNotificationBar = connect(mapStateToProps, { removeNotification })(NotificationBar);

export {
  ConnectedNotificationBar as NotificationBar
}

export default ConnectedNotificationBar