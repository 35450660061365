import React from 'react';
import { CommandResponse } from '../../api';
import { Box, Button, Typography } from '@mui/material';
import { Select, SelectOption } from '../common/Select';
import { ConfirmationDialog } from '../common/Modal';
import { connect } from 'react-redux';
import { ReduxState } from '../../reducers';
import { AlderonFriend } from '../../api/social.types';
import { FriendsTable } from './FriendsTable';

interface ReduxStateProps {
}
interface ReduxActionProps {
  
}

interface ComponentProps {
  friends: AlderonFriend[];
  loading: boolean;
  showPullServerPlayers: boolean;
  onPullServerPlayers: () => void;
  onPlayerSelected: (player: AlderonFriend) => void;
}

interface State {
  
}

type Props = ComponentProps & ReduxStateProps & ReduxActionProps;

class SocialFriendsComponent extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
  }


  render = () => {
    const { friends, loading, showPullServerPlayers} = this.props;
    return (
      <FriendsTable loading={loading} onPlayerSelected={this.props.onPlayerSelected} onPullServerPlayers={this.props.onPullServerPlayers} friends={friends} showPullServerPlayers={showPullServerPlayers} />
    );
  };
}

const mapStateToProps = ({  }: ReduxState) => {
  return {};
}

export const SocialFriends = connect(mapStateToProps, { })(SocialFriendsComponent);