import { GenericError } from "./types";

export interface PlatformInvitation {
    user_uuid: string;
    invitation_code: string;
    email: string;
    scopes: string;
    revoked: boolean;
    claimed: boolean;
    link: string;
    created_at: string;
}

export class PlatformInviteError extends GenericError {
    error: string
    error_description: string
    message: string

    constructor({ error, error_description, message }: PlatformInviteError) {
        super(message)
        this.error = error;
        this.error_description = error_description;
        this.message = message;
    }
  }
  