import axios from 'axios'
import moment from 'moment'

import { User } from '../auth.types'
import { FriendlyApiError, GenericError } from '../types'

const USERS_ENDPOINT = `/api/admin/users`


export const getUsers = async(): Promise<User[]> => {
    try {
        const { data } = await axios.get(USERS_ENDPOINT)
        const { items } = data
        if (!items) {
            throw new GenericError('Failed to retrieve users.')
        }

        return items.map((item: User) => {
            return {
                id: item.id,
                uuid: item.uuid,
                name: item.name,
                email: item.email,
                scope: item.scope,
                roles: item.roles,
                disabled: item.disabled,
                created_at: moment(item.created_at).format('MM/DD/YYYY'),
                alderonConnected: item.alderonConnected
            }
        })
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}
export const disableUser = async(uuid: string): Promise<void> => {
    try {
        await axios.post(`${USERS_ENDPOINT}/${uuid}/disable`)
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}

export const enableUser = async(uuid: string): Promise<void> => {
    try {
        await axios.post(`${USERS_ENDPOINT}/${uuid}/enable`)
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}

export const addScope = async(uuid: string, scope: string): Promise<void> => {
    try {
        await axios.post(`${USERS_ENDPOINT}/${uuid}/scopes/add`, { scope })
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}

export const removeScope = async(uuid: string, scope: string): Promise<void> => {
    try {
        await axios.post(`${USERS_ENDPOINT}/${uuid}/scopes/remove`, { scope })
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}