import { User } from "../api";
import { USER_UPDATE } from "../actions/types";

export interface AuthState {
    user?: User;
}

const InitialAuthState: AuthState = {};

export const AuthReducer = (state: AuthState = InitialAuthState, action: any) => {
    switch (action.type) {
        case USER_UPDATE:
            return {
                ...state,
                user: action.payload,
            };
    }
    return state;
}