import React, { FunctionComponent, ReactElement } from "react";

import { RequiresAuthentication } from "./RequiresAuthentication";
import { Box, SxProps } from "@mui/material";

export const Page: FunctionComponent<{ children: ReactElement<any, any>, sx?: SxProps }> = ({ children, sx }) => {
    return (
        <RequiresAuthentication>
            <Box sx={sx}>
                {children}
            </Box>
        </RequiresAuthentication>
    );
}