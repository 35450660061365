import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Typography, Modal, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material';
import { connectToAlderon, disconnectFromAlderon } from '../../actions/user';
import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { ReduxState } from '../../reducers';
import { User } from '../../api';
import { WithUpdateUserProps, withUpdateUser } from '../../common/hooks/withUpdateUser';
import { Page } from '../common/Page';
import { AlderonConnection } from '../alderon';

interface ReduxStateProps {
  scope: string;
  isRoot: boolean;
  isAdmin: boolean;
  hasAlderonScope: boolean;
}

interface ReduxActionProps { }

interface ComponentProps {}

interface AlderonConnectionState { }

export type AlderonConnectionProps = ComponentProps & ReduxStateProps & ReduxActionProps & withAuthProps;

class Account extends React.Component<AlderonConnectionProps, AlderonConnectionState> {
  state = {
  }

  render = () => {
    const { hasAlderonScope } = this.props;
    return (
      <Page>
          <Box sx={{ p: 5 }}>
            {hasAlderonScope && <AlderonConnection />}
          </Box>
      </Page>
    )
  }
}


const mapStateToProps = ({ auth }: ReduxState) => {
  const { user } = auth;
  const { scope } = user as User ?? {};
  const isRoot = scope?.includes('dinosync/root');
  const isAdmin = scope?.includes('dinosync/admin');
  const hasAlderonScope = scope?.includes('dinosync/alderon');
  return {
    scope,
    isRoot,
    isAdmin,
    hasAlderonScope,
  }
};

export const AccountComponent = withAuth(connect(mapStateToProps, {  })(Account));