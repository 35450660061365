import { SelectOption } from "../components/common/Select";
import { PULLING_USERS, SOCIAL_MAP_UPDATE } from "../actions/types";
import { AlderonFriend, SocialMap } from "../api/social.types";
import { ServerInfo } from "../api/servers.types";

export interface SocialState {
    map: SocialMap
    friends: SelectOption<AlderonFriend>[]
    servers: ServerInfo[]
    loading: {
        [key: string]: boolean
    }
    loadingSocialMap: boolean
}

export const SocialInitialState: SocialState = {
    map: {},
    friends: [],
    servers: [],
    loading: {},
    loadingSocialMap: false,
};

export const SocialReducer = (state = SocialInitialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case SOCIAL_MAP_UPDATE:
            return {
                ...state,
                map: payload.map ?? state.map,
                friends: payload.friends ?? state.friends,
                servers: payload.servers ?? state.servers,
                loadingSocialMap: payload.loadingSocialMap ?? state.loadingSocialMap,
            };
        case PULLING_USERS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ...payload,
                },
            };
    }
    return state;
}