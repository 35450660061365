import React from 'react';
/* eslint-disable react/prefer-stateless-function */
import { Link, NavLink, Navigate, NavigateFunction } from 'react-router-dom';
import { Box, TextField, Button, Typography, ImageListItem, FormControl } from '@mui/material';

import { withRouter } from '../../common/hooks/withParams';
import { withLogin } from '../../common/hooks/withLogin';
import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { connect } from 'react-redux';
import { ReduxState } from 'src/reducers';
import { addError } from 'src/actions/notifications';
import PasswordTextField from '../common/PasswordTextField';
const logo = require('../../assets/login.png')

export interface LoginState {
  email: string;
  password: string;
  error?: string;
  loading: boolean;
}
export interface LoginProps extends withAuthProps {
  navigate: NavigateFunction;
  login: (email: string, password: string) => Promise<any>;
  addError: (error: string) => void;
}

class LoginComponentMain extends React.Component<LoginProps, LoginState> {
  playerPollingId: any

  constructor(props: any) {
    super(props)
    this.state = {
      email: '',
      password: '',
      loading: false,
    }
  }

  onUserNameChanged = (e: any) => {
    this.setState({ email: e.target.value })
  }

  onPasswordChanged = (e: any) => {
    this.setState({ password: e.target.value })
  }

  onLogin = async() => {
    const { email, password } = this.state
    if(!email || !password) {
      this.setState({ error: 'Please enter an email and password'})

      return
    }

    this.setState({ error: undefined, loading: true })

    try {
      await this.props.login(email.trim(), password.trim())
      this.props.navigate('/')
    } catch(e: any) {
      this.setState({ error: `${e}` })
      this.props.addError(`${e}`)
    } 
    this.setState({ loading: false })
  }

  render = () => {
    const { email, password, error, loading } = this.state
    const { navigate, authenticated, authInitialized } = this.props
    if (authenticated && authInitialized) {
      return <Navigate to="/" />;
    }

    if(!authInitialized) {
      return null;
    }

    return (
      <Box sx={{ p: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ height: '100vh', maxWidth: '35rem', p: 6, display: 'flex', flexDirection: 'column', gap: 6 }}>
              <ImageListItem>
                <img
                  src={logo}
                  alt="Phone"
                />
              </ImageListItem>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.onUserNameChanged}
                  label="Email address"
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <PasswordTextField
                  variant="outlined"
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.onPasswordChanged}
                  label="Password"
                />
              </FormControl>
                <Button
                  sx={{
                    flex: 1,
                    mt: 2,
                    py: 2,
                    backgroundColor: 'blue.600',
                    '&:hover': { backgroundColor: 'blue.700' },
                    '&:active': { backgroundColor: 'blue.800' }
                  }}
                  variant="outlined"
                  disabled={loading}
                  onClick={this.onLogin}
                >
                  Sign in
                </Button>
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                mt: 2,
              }}>
                <Button
                  sx={{
                    flex: 1,
                    py: 2,
                    backgroundColor: 'blue.600',
                    '&:hover': { backgroundColor: 'blue.700' },
                    '&:active': { backgroundColor: 'blue.800' }
                  }}
                  variant="text"
                  href="/register"
                  to="/register"
                  LinkComponent={NavLink}
                >
                  Register
                </Button>
                <Button
                  sx={{
                    flex: 1,
                    py: 2,
                    backgroundColor: 'blue.600',
                    '&:hover': { backgroundColor: 'blue.700' },
                    '&:active': { backgroundColor: 'blue.800' }
                  }}
                  variant="text"
                  href="/forgot-password"
                  to="/forgot-password"
                  LinkComponent={NavLink}
                >
                  Forgot Password
                </Button>
              </Box>
              {error && (
                <Typography sx={{ mt: 5, color: 'red.700' }}>{error}</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
    
  }
}


export const LoginComponent = connect(null, { addError })(withAuth(withLogin(withRouter(LoginComponentMain))))