import axios from 'axios';
import React, { FunctionComponent } from 'react';

import { AuthenticationResponse, User } from '../../api/auth.types';
import { authenticate, userDetails, register as registerApi, refreshToken as performTokenRefresh } from '../../api/auth';
import { AuthContext, AuthContextProps, AuthenticationParams } from "../context";
import { setRef } from '@mui/material';

const REFRESH_TOKEN = 'REFRESH_TOKEN'
const AUTH_TOKEN = 'AUTH_TOKEN'
const USER = 'USER'

const initialState: AuthContextProps = {
  authenticated: false,
  loading: true,
  initialized: false,
  setAuthentication: (authenticationParams: AuthenticationParams) => { },
  login: (email, password) => { },
  register: (name, email, password, confirmPassword) => { },
  logout: () => {},
  updateUser: (user: Partial<User>) => { },
};

export interface AuthProviderProps {
  children?: React.ReactNode;
}

export const AuthProvider: FunctionComponent<
AuthProviderProps
> = ({ children }): JSX.Element => {
  const [props, setProps] = React.useState<AuthContextProps>(initialState)
  const [accessToken, setToken] = React.useState<string | null | undefined>()
  const [refreshToken, setRefreshToken] = React.useState<string | null | undefined>()
  const [user, setUser] = React.useState<User | null |  undefined>()
  const [initialized, setInitialized] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    setUser(localStorage.getItem(USER) ? JSON.parse(localStorage.getItem(USER) as string) : null)
    setToken(localStorage.getItem(AUTH_TOKEN))
    setRefreshToken(localStorage.getItem(REFRESH_TOKEN))
    setInitialized(true)
    setLoading(false)
  }, [])

  const logout = React.useCallback((reload = true) => {
    localStorage.removeItem(USER)
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    if (reload) {
      window.location.reload()
    }
  }, [])

  const updateUser = React.useCallback((update: Partial<User>) => {
    const updatedUser = { ...user, ...update }
    setUser(updatedUser as User)
    localStorage.setItem(USER, JSON.stringify(updatedUser))
  }, [user])

  React.useEffect(() => {
    (axios.defaults.headers as any)['x-access-token'] = accessToken;
    if (accessToken) {
      axios.interceptors.response.use(
        response => response,
        async (error) => {
          const originalRequest = error.config;
      
          // Checking if the error is due to an Invalid Token and the request has not been retried yet
          if (error.response?.data?.message.includes('InvalidToken') && !originalRequest._retry) {
            originalRequest._retry = true; // Mark the request as retried
      
            try {
              const { access_token, refresh_token } = await performTokenRefresh(user?.id ?? '', refreshToken as string); // Fetching a new token
              (axios.defaults.headers as any)['x-access-token'] = access_token;
              originalRequest.headers['x-access-token'] = access_token;
              const result = await axios(originalRequest);
              if (access_token) {
                setToken(access_token);
                localStorage.setItem(AUTH_TOKEN, access_token);
              }
              if (refresh_token) {
                localStorage.setItem(REFRESH_TOKEN, refresh_token);
                setRefreshToken(refresh_token);
              }
              return result
            } catch (refreshError) {
              logout();
              return Promise.reject(refreshError); // Rejecting with the refresh error
            }
          }
      
          // For other errors or if the retry has already happened, proceed without retrying
          return Promise.reject(error);
        }
      );
      (async () => {
        try {
          const { id, name, scope, roles, alderonConnected } = await userDetails(accessToken as string);
          const user = { id, name, email: '', scope, roles, alderonConnected }
          setUser(user)
          localStorage.setItem(USER, JSON.stringify(user))
        } catch (e) {
          console.error(e)
        }
      })()
    }
  }, [accessToken, refreshToken])

  const login = React.useCallback(async(email: string, password: string): Promise<AuthenticationResponse> => {
    setLoading(true)
    try {
      const response = await authenticate(email, password)
      const { access_token: token, refresh_token: refreshToken } = response
      if (!token) {
        throw new Error('Token not retrieved')
      }
  
      setToken(token)
      setRefreshToken(refreshToken)
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      const { id, name, scope, roles, alderonConnected } = await userDetails(token as string);
      const user = { id, name, email, scope, roles, alderonConnected }
  
      localStorage.setItem(USER, JSON.stringify(user))
      setUser(user)
      setLoading(false)

      return response
    } catch (e) {
      setLoading(false)
      logout(false)
      throw e
    }
  }, [])

  const register = React.useCallback(async(name: string, email: string, password: string, confirmPassword: string): Promise<AuthenticationResponse> => {
    setLoading(true)
    try {
      const response = await registerApi(name, email, password, confirmPassword)
      const { access_token: token, refresh_token: refreshToken } = response
      if (!token) {
        throw new Error('Unknown registration occured, please try again')
      }
  
      setToken(token)
      setRefreshToken(refreshToken)
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      const { id, scope, roles, alderonConnected } = await userDetails(token as string);
      const user = { id, name, email, scope, roles, alderonConnected }
  
      localStorage.setItem(USER, JSON.stringify(user))
      setUser(user)
      setLoading(false)

      return response
    } catch (e) {
      setLoading(false)
      throw e
    }
  }, [])

  React.useEffect(() => {
    setProps({
      authenticated: !!accessToken,
      user: user || undefined,
      accessToken: accessToken || undefined,
      loading,
      initialized,
      setAuthentication: ({ user, accessToken }) => {
        setToken(accessToken)
        setUser(user)
      },
      updateUser,
      logout,
      login,
      register,
    })
  }, [accessToken, initialized, user, login, logout, register, setToken, setUser, updateUser, loading])

  return (
    <AuthContext.Provider value={props}>
      {children}
    </AuthContext.Provider>
  );
};
