import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../reducers';
import { useAuth } from '../../common/hooks/useAuth';
import { updateUser } from '../../actions/user';
import { User } from '../../api';

interface Props {
    updateUser: (user?: User) => void;
}

const UserSync: React.FunctionComponent<Props> = ({ updateUser }) => {
    const { user } = useAuth();
    React.useEffect(() => {
        updateUser(user);
    }, [user]);

    return null;
}

const mapStateToProps = (state: ReduxState) => {
    return {};
}

export default connect(mapStateToProps, { updateUser })(UserSync);