import { DispatchFunction, GetStateFunction } from "../reducers";
import { PULLING_USERS, SERVER_PLAYERS_UPDATE, SOCIAL_MAP_UPDATE, USER_SERVER_UPDATE } from "./types";
import { getServerDetails, getServerPlayers, getSocialMap, getUserDetails, getUserServer, pinServer as pinServerApi, unpinServer as unpinServerApi } from "../api/social";
import { ALDERON_FRIEND_TYPE_KEYS, ALDERON_FRIEND_TYPE_VALUES, AlderonFriend, AlderonFriendType, ProcessedSocialMap, ServerProvider, SocialGroup, SocialMap } from "../api/social.types";
import { SelectOption } from "../components/common/Select";
import { ServerInfo } from "../api/servers.types";
import { addPlayerTarget, removePlayerTarget } from "..//api/player-targets";
import { AlderonConnectionError } from "../api";
import { addError } from "./notifications";
import { addPlayerToPlayerGroupById } from "./groups";

export const processSocialMap = async (socialMap: SocialMap): Promise<ProcessedSocialMap> => {
    const { offline, ...rest } = socialMap;
    const groups = rest as SocialMap;
    const userServerId = Object.keys(groups).find(key => groups[key].friends.find(friend => friend.type === AlderonFriendType.SELF));
    const pinnedServerIds = Object.keys(groups).filter(key => groups[key].pinned && key !== userServerId);
    const userServerProvider = userServerId ? groups[userServerId].server?.server?.provider : ServerProvider.Panjura;

    const panjuraKeys = Object.keys(rest).filter(key => groups[key].server?.server?.provider === ServerProvider.Panjura && key !== userServerId && !pinnedServerIds.includes(key));
    const gondowaKeys = Object.keys(rest).filter(key => groups[key].server?.server?.provider === ServerProvider.Gondowa && key !== userServerId && !pinnedServerIds.includes(key));
    const communityKeys = Object.keys(rest).filter(key => ![ServerProvider.Gondowa, ServerProvider.Panjura].includes(groups[key].server?.server?.provider) && key !== userServerId  && !pinnedServerIds.includes(key));
    const providerBuckets: { [key: string]: string[]} = {
        [ServerProvider.Panjura]: panjuraKeys,
        [ServerProvider.Gondowa]: gondowaKeys,
        [ServerProvider.Community]: communityKeys,
    }
    const providerBucketKeys = Object.keys(providerBuckets).sort((a, b) => {
        if (a === userServerProvider) {
            return -1;
        }
        if (b === userServerProvider) {
            return 1;
        }
        return 0;
    });
    const orderedKeys = providerBucketKeys.reduce((acc, key) => {
        return [...acc, ...providerBuckets[key]];
    }, [userServerId, ...pinnedServerIds] as string[]).filter(key => key);

    const ordered = orderedKeys.reduce((acc, key) => {
        const group = groups[key] as SocialGroup;
        const {
            server,
            friends,
            pinned,
            visited,
            server_info,
        } = group;
        const orderedFriends =  friends.sort((a, b) => {
          const aTypeIndex = ALDERON_FRIEND_TYPE_VALUES.indexOf(a.type);
          const bTypeIndex = ALDERON_FRIEND_TYPE_VALUES.indexOf(b.type);

          return aTypeIndex - bTypeIndex;
        });
        return {...acc, [key]: {
            server_info,
            server,
            friends: orderedFriends,
            pinned,
            visited,
        }};
    }, {} as SocialMap);

    const friends: AlderonFriend[] = Object.keys(socialMap).reduce((acc: AlderonFriend[], key) => {
        const group = socialMap[key] as SocialGroup;
        return [...acc, ...group.friends];
    }, []);
    const friendOptions: SelectOption<AlderonFriend>[] = friends.map(friend => ({
        label: friend.display_name,
        value: friend,
        item: friend,
    }));

    const serverPromises = Object.keys(ordered).map(async (key) => {
        const item = ordered[key];
        if (item?.server_info) {
            return item?.server_info;
        }
        const serverKey = item.server?.server.key;

        const server = await getServerDetails(serverKey as string);

        return server;
    });

    const servers = await Promise.all(serverPromises);
    const serverMaps = servers.reduce((acc, server) => {
        return {...acc, [server.id.uuid]: server};
    }, {} as {[key: string]: ServerInfo});

    const map = {
        ...ordered,
    };
    if (offline) {
        map.offline = offline;
    }

    return { friends: friendOptions, servers: serverMaps, map, pinned: pinnedServerIds };
}

export const loadSocialMap = () => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: SOCIAL_MAP_UPDATE, payload: { loadingSocialMap: true } });
        const socialMap = await getSocialMap();
        const { map, friends, servers, pinned } = await processSocialMap(socialMap);

        const { social: { map: stateMap, servers: stateServers, friends: stateFriends} } = getState();

        const updatedMap = Object.keys(map).reduce((acc, key) => {
            const itemServer = map[key];
            const stateServer = stateMap[key];
            if (stateServer) {
                const filteredStateServerFriends = stateServer.friends.filter(friend => !itemServer.friends.find(f => f.id.identifier === friend.id.identifier));
                const updatedFriends = [...filteredStateServerFriends, ...itemServer.friends].sort((a, b) => {
                    const aTypeIndex = ALDERON_FRIEND_TYPE_VALUES.indexOf(a.type);
                    const bTypeIndex = ALDERON_FRIEND_TYPE_VALUES.indexOf(b.type);
        
                    return aTypeIndex - bTypeIndex;
                });
                return {...acc, 
                    [key]: {
                        server: stateServer.server,
                        friends: updatedFriends,
                }};
            }

            return {...acc, [key]: itemServer};
        }, {} as SocialMap);

        const filteredStateFriends = stateFriends.filter(friend => !friends.find(f => f.value.id.identifier === friend.value.id.identifier));
        const updatedFriends = [...filteredStateFriends, ...friends];
        const updatedServers = { ...stateServers, ...servers };

        dispatch({ type: SOCIAL_MAP_UPDATE, payload: { map: updatedMap, friends: updatedFriends, servers: updatedServers, pinned, loadingSocialMap: false} });
    } catch (e: any) {
        dispatch({ type: SOCIAL_MAP_UPDATE, payload: { loadingSocialMap: false } });
        if (e instanceof AlderonConnectionError) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('Failed to load social map'));
        }
    }
}

export const loadUserServerMap = () => async (dispatch: DispatchFunction, getState: GetStateFunction ) => {
    const socialMap = await getUserServer();
    const { map, friends, servers } = await processSocialMap(socialMap);
    const { offline, ...userMap } = map
    if (offline) {
        userMap.offline = offline;
    }
    const { social: { map: stateMap, servers: stateServers, friends: stateFriends} } = getState();
    const updatedMap = { ...stateMap, ...map };
    const filteredStateFriends = stateFriends.filter(friend => !friends.find(f => f.value.id.identifier === friend.value.id.identifier));
    const updatedFriends = [...filteredStateFriends, ...friends];
    const updatedServers = { ...stateServers, ...servers };

    dispatch({ type: SOCIAL_MAP_UPDATE, payload: { map: updatedMap, friends: updatedFriends, servers: updatedServers } });
}

export const loadServerPlayers = (serverKey: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    dispatch({ type: PULLING_USERS, payload: {
        [serverKey]: true,
     } });
    const socialMap = await getServerPlayers(serverKey);
    const { map, friends, servers } = await processSocialMap(socialMap);
    const { social: { map: stateMap, servers: stateServers, friends: stateFriends} } = getState();
    const updatedMap = { ...stateMap, ...map };
    const filteredStateFriends = stateFriends.filter(friend => !friends.find(f => f.value.id.identifier === friend.value.id.identifier));
    const updatedFriends = [...filteredStateFriends, ...friends];
    const updatedServers = { ...stateServers, ...servers };

    dispatch({ type: SOCIAL_MAP_UPDATE, payload: { map: updatedMap, friends: updatedFriends, servers: updatedServers } });
    dispatch({ type: PULLING_USERS, payload: {
        [serverKey]: false,
     } });
}

export const trackPlayer = (alderonId: string, type: AlderonFriendType, groupId?: string) => async (dispatch: DispatchFunction | any, getState: GetStateFunction) => {
    if (type === AlderonFriendType.NONE) {
        await removePlayerTarget(alderonId)
    } else {
        await addPlayerTarget(alderonId, type)
    }
    if (groupId) {
        dispatch(addPlayerToPlayerGroupById(groupId, alderonId, ''))
    }
    const { social: { map: stateMap, friends, servers } } = getState();
    const updatedFriends = friends.map(friend => {
        if (friend.value.id.identifier === Number(alderonId.toString().replace(/-/g, ''))) {
            return {
                ...friend,
                value: {
                    ...friend.value,
                    type,
                }
            }
        }
        return friend;
    });
    const updatedMap = Object.keys(stateMap).reduce((acc, key) => {
        const group = stateMap[key];
        const updatedFriends = group.friends.map(friend => {
            if (friend.id.identifier === Number(alderonId.toString().replace(/-/g, ''))) {
                return {
                    ...friend,
                    type,
                }
            }
            return friend;
        });
        return {...acc, [key]: {
            ...group,
            friends: updatedFriends,
        }};
    }, {} as SocialMap);
    dispatch({ type: SOCIAL_MAP_UPDATE, payload: { map: updatedMap, friends: updatedFriends, servers } });
}

export const pinServer = (serverUuid: string, serverKey: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { social: { map: stateMap } } = getState();
    const updatedMap = Object.keys(stateMap).reduce((acc, key) => {
        const group = stateMap[key];
        if (key === serverUuid) {
            return {
                ...acc, 
                [key]: {
                    ...group,
                    pinned: true,
                }
            };
        }
        return {...acc, [key]: {...group}};
    }, { ...stateMap });

    dispatch({ type: SOCIAL_MAP_UPDATE, payload: { map: updatedMap } });

    const success = await pinServerApi(serverKey);
    if (!success) {
        dispatch(addError('Failed to pin server'));
        dispatch({ type: SOCIAL_MAP_UPDATE, payload: { map: stateMap } });
    }
}

export const unpinServer = (serverUuid: string, serverKey: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { social: { map: stateMap } } = getState();
    const updatedMap = Object.keys(stateMap).reduce((acc, key) => {
        const group = stateMap[key];
        if (key === serverUuid) {
            return {...acc, [key]: {
                ...group,
                pinned: false,
            }};
        }
        return {...acc, [key]: group};
    }, { ...stateMap });

    dispatch({ type: SOCIAL_MAP_UPDATE, payload: { map: updatedMap } });

    const success = await unpinServerApi(serverKey);
    if (!success) {
        dispatch(addError('Failed to unpin server'));
        dispatch({ type: SOCIAL_MAP_UPDATE, payload: { map: stateMap } });
    }
}