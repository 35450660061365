import React from 'react';
import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { ReduxState } from '../../reducers';
import { connect } from 'react-redux';
import { loadServerPlayers, loadUserServerMap } from '../../actions/social';
import { getPlayerGroups } from '../../actions/groups';
import { AlderonFriend, SocialMap } from '../../api/social.types';
import { SelectOption } from '../common/Select';
import { SocialComponent } from './SocialComponent';
import { ServerInfo } from '../../api/servers.types';

interface ReduxStateProps {
  map: SocialMap
  friends: SelectOption<AlderonFriend>[]
  servers: { [key: string]: ServerInfo }
}

interface ReduxActionProps {
  loadUserServerMap: (expandServerPlayers?: boolean) => void;
  loadServerPlayers: (serverKey: string)=> void;
}

interface ComponentProps extends withAuthProps {}

interface SocialState {
  map: SocialMap
}

export type SocialProps = ComponentProps & ReduxStateProps & ReduxActionProps & withAuthProps;

class SocialMapComponent extends React.Component<SocialProps, SocialState> {

  componentDidMount(): void {
    this.props.loadUserServerMap()
    this.props.getPlayerGroups()
  }

  onPullServerPlayers = () => {
    const { servers } = this.props;
    const serverKeys = Object.keys(servers);
    if (serverKeys.length === 0) {
      return;
    }
    const serverKey = servers[serverKeys[0]].id.key
    this.props.loadServerPlayers(serverKey)
  }

  render = () => {
    const { friends, servers, map } = this.props;
    return (
      <SocialComponent
        map={map}
        friends={friends}
        onRefresh={this.props.loadUserServerMap}
        servers={servers}
        onPullServerPlayers={this.onPullServerPlayers}
      />
    )
  }
}


const mapStateToProps = ({ social }: ReduxState) => {
  return {
    map: social.map,
    friends: social.friends,
    servers: social.servers,
  }
};

export const UserServer = withAuth(connect(mapStateToProps, {loadUserServerMap, loadServerPlayers, getPlayerGroups})(SocialMapComponent));