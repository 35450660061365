import React from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';

type Props = {
    title: string;
    compareTime?: string;
    expiresAt: string;
};

type State = {
  timeLeft: string;
};

export class CountdownTimer extends React.Component<Props, State> {
  private timerID?: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);
    this.state = { timeLeft: this.calculateTimeLeft() };
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    if (this.timerID) {
      clearInterval(this.timerID);
    }
  }

  tick() {
    this.setState({
      timeLeft: this.calculateTimeLeft(),
    });
  }

  calculateTimeLeft(): string {
    const { expiresAt, compareTime } = this.props;
    const compare = compareTime ?? moment().toISOString();

    return friendlyTimeDifference(compare, expiresAt);
  }

  render() {
    const { title } = this.props;
    const { timeLeft } = this.state;
    return (
        <Box sx={{display: 'flex', flexDirection: 'row'}}>
            {title && <Typography sx={{ fontWeight: 'bold'}}>{title}: </Typography>}
            <Typography sx={{ fontStyle: 'italic'}}>{timeLeft}</Typography>
        </Box>
    )
  }
}

const friendlyTimeDifference = (date1: string, date2: string): string => {
  let dateA = moment(date1);
  let dateB = moment(date2);

  if (dateA.isAfter(dateB)) {
    return 'Expired';
  }

  const duration = moment.duration(dateB.diff(dateA));

  const hours = Math.abs(duration.hours());
  const minutes = Math.abs(duration.minutes());
  const seconds = Math.abs(duration.seconds());

  const parts = [
    hours > 0 ? `${hours} hour${hours !== 1 ? 's' : ''}` : '',
    minutes > 0 ? `${minutes} min${minutes !== 1 ? 's' : ''}` : '',
    seconds > 0 && minutes === 0 && hours === 0 ? `${seconds} sec${seconds !== 1 ? 's' : ''}` : ''
  ].filter(part => part !== '');

  const friendlyDescription = parts.join(' ');

  return friendlyDescription;
};
