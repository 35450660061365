import axios from 'axios';
import { ServerInfo } from './servers.types';
import { AlderonFriendType } from './social.types';

const PLAYER_TARGETS = '/api/player-targets'


export const addPlayerTarget = async(alderonId: string, type: string): Promise<ServerInfo>=> {
    try {
        const { data } = await axios.post(PLAYER_TARGETS, {
            targetId: Number(alderonId.replace(/-/g, '')),
            type,
        })

        return data.data as ServerInfo;
    } catch (e) {
        console.error(e);
    }

    return {} as ServerInfo;
}

export const removePlayerTarget = async(alderonId: string): Promise<ServerInfo>=> {
    try {
        const { data } = await axios.delete(`${PLAYER_TARGETS}/${alderonId}`)

        return data.data as ServerInfo;
    } catch (e) {
        console.error(e);
    }

    return {} as ServerInfo;
}

export const addPlayerTargets = async(agids: number[] | string[] | (number | string)[], type: AlderonFriendType | null): Promise<ServerInfo>=> {
    try {
        const ids = agids.map((agid) => Number(agid.toString().replace(/-/g, '')))
        await axios.post(`${PLAYER_TARGETS}/players`, {
            ids,
            type,
        })

    } catch (e) {
        console.error(e);
    }

    return {} as ServerInfo;
}