import React from 'react';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import ClipLoader from "react-spinners/ClipLoader";
import { withAuthProps } from '../../common/hooks/withAuth';
import { Page } from '../common/Page';
import { SocialGroup } from './SocialGroup';
import { AlderonFriend, AlderonFriendType, AlderonFriendTypeMapping, PLAYER_TRACK_TYPES, SocialMap } from '../../api/social.types';
import { Select, SelectOption } from '../common/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationDialog } from '../common/Modal';
import { connect } from 'react-redux';
import { trackPlayer } from '../../actions/social';
import { ServerInfo } from '../../api/servers.types';
import { formatAlderonIdInput } from '../../common/utils/utils';
import { PlayerGroup, PlayerGroupUser } from '../../api/groups.types';
import { ReduxState } from '../../reducers';

const NONE_GROUP = { label: 'None', value: '' };

interface ReduxStateProps {}

interface ReduxActionProps {
  trackPlayer: (alderonId: string, type: string, groupId?: string) => void;
}

interface ComponentProps {
  map: SocialMap
  friends: SelectOption<AlderonFriend>[]
  servers: { [key: string]: ServerInfo }
  groups: PlayerGroup[]
  groupPlayers: {
      [key: string]: PlayerGroupUser[]
  }
  loadingSocialMap: boolean
  onRefresh: () => void;
  onPullServerPlayers: (serverKey: string) => void;
}

interface SocialState {
  selectedFriend: SelectOption<AlderonFriend> | null;
  map: SocialMap;
  showTrackPlayer: boolean;
  alderonId: string;
  trackType: SelectOption<string> | null;
  groupOptions: SelectOption<string>[];
  selectedGroupOption: SelectOption<string> | null;
  selectedPlayer?: AlderonFriend
  selectedPlayerGroupNames: string[]
}

export type SocialProps = ComponentProps & ReduxStateProps & ReduxActionProps & withAuthProps;

class SocialComponentWrapper extends React.Component<SocialProps, SocialState> {

  constructor(props: SocialProps) {
    super(props)
    this.state = {
      selectedFriend: null,
      map: props.map,
      showTrackPlayer: false,
      alderonId: '',
      trackType: PLAYER_TRACK_TYPES[0],
      groupOptions: [],
      selectedGroupOption: NONE_GROUP,
      selectedPlayerGroupNames: []
    }
  }

  componentDidMount(): void {
      this.updateGroupOptions();
  }

  updateGroupOptions = () => {
    const { groups } = this.props;
    const options = groups.filter(item => item.owner).map((group) => {
      return {
        label: group.name,
        value: group.uuid,
      }
    });

    this.setState({ groupOptions: [NONE_GROUP, ...options] });
  }

  componentDidUpdate(prevProps: Readonly<SocialProps>, prevState: Readonly<SocialState>, snapshot?: any): void {
    if (prevProps.map !== this.props.map || prevProps.friends !== this.props.friends || prevState.selectedFriend !== this.state.selectedFriend) {
      this.onUpdateMap()
    }
    if (prevProps.groups !== this.props.groups) {
      this.updateGroupOptions();
    }
  }

  onFriendSelected = (item: SelectOption<AlderonFriend>) => {
    this.setState({ selectedFriend: item });
  }

  onPlayerSelected = (player: AlderonFriend) => {
    let trackType = PLAYER_TRACK_TYPES.find((type) => type.value === player.type) ?? PLAYER_TRACK_TYPES[0];
    if (trackType.value === AlderonFriendType.NONE) {
      trackType = PLAYER_TRACK_TYPES[0];
    }
    const selectedPlayerGroupIds = Object.keys(this.props.groupPlayers).filter(groupId => {
      return this.props.groupPlayers[groupId].find((groupPlayer) => groupPlayer.agid === player.id.identifier)
    })
    const selectedPlayerGroupNames = selectedPlayerGroupIds.map(id => this.props.groups.find(group => group.uuid === id)?.name || '').filter(item => item)

    this.setState({ showTrackPlayer: true, alderonId: player.id.display, trackType, selectedPlayer: player, selectedPlayerGroupNames });
  }

  onUpdateMap = () => {
    const { selectedFriend } = this.state;
    if (!selectedFriend) {
      this.setState({ map: this.props.map });

      return;
    }
    const filtered = Object.keys(this.props.map).reduce((acc: SocialMap, key: string) => {
      const group = this.props.map[key];
      const friend = group.friends.find((friend) => {
        return friend.id.display === selectedFriend?.value.id.display;
      });
      if (!friend) {
        return acc;
      }
      return {...acc, [key]: group};
    }, {  });

    this.setState({ map: filtered });
  }

  onCloseTrackPlayer = () => {
    this.setState({ showTrackPlayer: false, alderonId: '', trackType: PLAYER_TRACK_TYPES[0], selectedPlayer: undefined, selectedPlayerGroupNames: [], selectedGroupOption: NONE_GROUP });
  }

  onSubmitTrackPlayer = () => {
    const { alderonId, trackType, selectedGroupOption } = this.state;
    this.onCloseTrackPlayer();
    if (alderonId.toString().length !== 11) {
      alert('Invalid Alderon Id');

      return;
    }

    this.props.trackPlayer(alderonId, trackType?.value || AlderonFriendType.KOS, selectedGroupOption?.value)
  }

  onTrackPlayer = () => {
    this.setState({ showTrackPlayer: true, selectedGroupOption: NONE_GROUP });
  }

  onAlderonIdChange = (e: any) => {
    this.setState({ alderonId: formatAlderonIdInput(e.target.value) });
  }

  onTrackTypeChanged = (item: SelectOption<string>) => {
    this.setState({ trackType: item });
  }

  onGroupSelected = (item: SelectOption<string>) => {
    this.setState({ selectedGroupOption: item });
  }

  render = () => {
    return (
        <Page>
            <Box sx={{ p: 5 }}>
            <ConfirmationDialog
              id="track-player"
              title="Track Player"
              visible={this.state.showTrackPlayer}
              okButtonText='Track'
              cancelButtonText='Cancel'
              onConfirmed={this.onSubmitTrackPlayer}
              onClose={this.onCloseTrackPlayer}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  width: '100%', 
                }}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: {
                      xxs: 'flex-start',
                      sm: 'center',
                    },
                    flexDirection: {
                      xxs: 'column',
                      sm: 'row',
                    },
                  }}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        variant="outlined"
                        type="text"
                        name="Alderon Id"
                        value={this.state.alderonId}
                        onChange={this.onAlderonIdChange}
                        label={`Alderon Id ${this.state.selectedPlayer ? '(Read-Only)' : ''}`}
                        disabled={!!this.state.selectedPlayer}
                      />
                    </FormControl>
                    <Select sx={{ width: '100%', mt: { xxs: 2, sm: 0 }, ml: { xxs: 0, sm: 2 } }} label={`Type ${this.state.selectedPlayer?.friends ? '(Read-Only)' : ''}`} value={this.state.trackType} items={PLAYER_TRACK_TYPES} onItemSelected={this.onTrackTypeChanged} clearable disableSearch disabled={this.state.selectedPlayer?.friends} />
                  </Box>
                  {this.state.selectedPlayer &&
                    <Box>
                      <Typography>Groups: </Typography>
                      <Typography>{this.state.selectedPlayerGroupNames.length ? this.state.selectedPlayerGroupNames.join(', ') : 'Player isn\'t in any groups' }</Typography>
                    </Box>
                  }
                    <Select sx={{ width: '100%' }} label={'Add Group (optional)'} value={this.state.selectedGroupOption} items={this.state.groupOptions} onItemSelected={this.onGroupSelected} clearable disableSearch />
                </Box>
            </ConfirmationDialog>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' , alignItems: 'center' }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: {
                    xxs: 'flex-start',
                    sm: 'center',
                  },
                  justifyContent: 'space-between',
                  flexDirection: {
                    xxs: 'column',
                    sm: 'row',
                  },
                }}>
                  <Select sx={{ maxWidth: '20rem', width: '15rem' }} label={'Player'} value={this.state.selectedFriend} items={this.props.friends} onItemSelected={this.onFriendSelected} clearable/>
                  <Button sx={{ ml: { xxs: 0, sm: 2}, alignItems: 'center' }} onClick={this.onTrackPlayer}>Track Players</Button>
                </Box>
                <Button sx={{ ml: 2 }} onClick={this.props.onRefresh}><FontAwesomeIcon icon={faRefresh} /></Button>
              </Box>
              {this.props.loadingSocialMap && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 500, pointerEvents: 'none', mt: 5, mb: 5 }}>
                    <ClipLoader loading={true} size={30} />
                </Box>                
              )}
              {Object.keys(this.state.map).map((key: string) => {
                return <SocialGroup key={key} group={this.props.map[key]} servers={this.props.servers} onPlayerSelected={this.onPlayerSelected} onPullServerPlayers={this.props.onPullServerPlayers} />
              })}
            </Box>
        </Page>
    );
  }
}

const mapStateToProps = ({ groups }: ReduxState) => {
  return {
    groups: groups.groups,
    groupPlayers: groups.players
  }
}


export const SocialComponent = connect<ReduxStateProps, ReduxActionProps, ComponentProps, any>(mapStateToProps, {trackPlayer})(SocialComponentWrapper);