import { User } from "../api";
import { USERS_UPDATE, USERS_LOADING } from "../actions/types";

export interface UsersState {
    items: User[];
    loadingUsers?: boolean;
}

const InitialState: UsersState = {
    items: [],
    loadingUsers: false,
};

export const UsersReducer = (state: UsersState = InitialState, action: any) => {
    switch (action.type) {
        case USERS_LOADING:
            return {
                ...state,
                loadingUsers: action.payload,
            };
        case USERS_UPDATE:
            return {
                ...state,
                items: action.payload,
            };
    }
    return state;
}

export default UsersReducer;