import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { Page } from '../Page';

export const NotFound: FunctionComponent = () => {
    return (
        <Page>
            <Box sx={{ 
                    marginTop: '5rem', 
                    padding: '5rem',
                    backgroundColor: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                 }}>
                    <h3>404 Error!</h3>
            </Box>
        </Page>
    )
}
export default NotFound