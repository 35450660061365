import { PlatformInvitation } from "../api/platformInvites.types";
import { PLATFORM_INVITES_UPDATE, INVITE_LINK, PLATFORM_INVITES_LOADING } from "../actions/types";

export interface PlatformInvitesState {
    items: PlatformInvitation[];
    generatedInviteLink: string | null;
    loadingInvites?: boolean;
}

const InitialState: PlatformInvitesState = {
    items: [],
    loadingInvites: false,
    generatedInviteLink: null,
};

export const PlatformInvitesReducer = (state: PlatformInvitesState = InitialState, action: any) => {
    switch (action.type) {
        case PLATFORM_INVITES_LOADING:
            return {
                ...state,
                loadingInvites: action.payload,
            };
        case PLATFORM_INVITES_UPDATE:
            return {
                ...state,
                items: action.payload,
            };
        case INVITE_LINK:
            return {
                ...state,
                generatedInviteLink: action.payload,
            };
    }
    return state;
}

export default PlatformInvitesReducer;