import { SelectOption } from "../components/common/Select";
import { AlderonFriendType, AlderonFriendTypeMapping } from "./social.types";

export interface PlayerGroupUser {
    uuid: string;
    group_uuid: string;
    agid: number;
    name: string;
    verified_agid: boolean;
    notes?: string;
  }
  export interface PlayerGroup {
    uuid: string;
    owner_uuid: string;
    name: string;
    type: string;
    players?: PlayerGroupUser[];
    owner?: boolean;
    owner_name?: string;
  }
  
export const GROUP_TYPES: SelectOption<string>[] = [
  { label: AlderonFriendTypeMapping[AlderonFriendType.ALLY], value: AlderonFriendType.ALLY },
  { label: AlderonFriendTypeMapping[AlderonFriendType.KOS], value: AlderonFriendType.KOS },
  { label: AlderonFriendTypeMapping[AlderonFriendType.WATCH], value: AlderonFriendType.WATCH },
  { label: AlderonFriendTypeMapping[AlderonFriendType.FRIEND], value: AlderonFriendType.FRIEND },
];
