import { User } from "../../api";
import { GetStateFunction, DispatchFunction } from "../../reducers";
import { enableUser as enableUserApi,  getUsers as getUsersAPI, disableUser as disableUserApi, addScope, removeScope } from "../../api/admin/users";
import { addError } from "../notifications";
import { USERS_LOADING, USERS_UPDATE } from "../types";


export const getUsers = ()  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: USERS_LOADING, payload: true });
        const items = await getUsersAPI();

        dispatch({ type: USERS_UPDATE, payload: items });
        dispatch({ type: USERS_LOADING, payload: false });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while generating the user link. Please try again.'));
        }
        
        dispatch({ type: USERS_LOADING, payload: false });
    }
}

export const disableUser = (uuid: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await disableUserApi(uuid);
        
        const { users } = getState();
        const { items: stateItems } = users;
        const updatedItems = stateItems.map((item: User) => {
            if (item.uuid === uuid) {
                return {
                    ...item,
                    disabled: true,
                }
            }

            return item;
        });

        dispatch({ type: USERS_UPDATE, payload: updatedItems });
    } catch (e: any) {
        console.error(e);
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while revoking the user, please try again.'));
        }
    }
}

export const enableUser = (uuid: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await enableUserApi(uuid);
        
        const { users } = getState();
        const { items: stateItems } = users;
        const updatedItems = stateItems.map((item: User) => {
            if (item.uuid === uuid) {
                return {
                    ...item,
                    disabled: false,
                }
            }

            return item;
        });

        dispatch({ type: USERS_UPDATE, payload: updatedItems });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            console.error(e);
            dispatch(addError('An error occurred while enabling the user, please try again.'));
        }
    }
}

export const updateUserPermissions = (uuid: string, added: string[], removed: string[])  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        if (added.length) {
            await addScope(uuid, added.join(' '));   
        }
        if (removed.length) {
            await removeScope(uuid, removed.join(' '));
        }

        dispatch(getUsers() as any);
    } catch (e: any) {
        dispatch(getUsers() as any);
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            console.error(e);
            dispatch(addError('An error occurred while enabling the user, please try again.'));
        }
    }

}

export const addUserScope = (uuid: string, scope: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await addScope(uuid, scope);
        
        const { users } = getState();
        const { items: stateItems } = users;
        const updatedItems = stateItems.map((item: User) => {
            if (item.id === uuid) {
                return {
                    ...item,
                    scope: `${item.scope} ${scope}`,
                }
            }

            return item;
        });

        dispatch({ type: USERS_UPDATE, payload: updatedItems });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            console.error(e);
            dispatch(addError('An error occurred while enabling the user, please try again.'));
        }
    }
}

export const removeUserScope = (uuid: string, scope: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await removeScope(uuid, scope);
        
        const { users } = getState();
        const { items: stateItems } = users;
        const updatedItems = stateItems.map((item: User) => {
            if (item.id === uuid) {
                return {
                    ...item,
                    scope: item.scope.split(' ').filter((s: string) => s !== scope).join(' '),
                }
            }

            return item;
        });

        dispatch({ type: USERS_UPDATE, payload: updatedItems });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            console.error(e);
            dispatch(addError('An error occurred while enabling the user, please try again.'));
        }
    }
}