import React, { Component } from 'react';
import { Box, Drawer, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Routes, Route, Link } from 'react-router-dom';

// Import your sub-components for Users, Invites, and Logs
import UsersComponent from './UsersComponent';
import InvitesComponent from './InvitesComponent';

const drawerWidth = 240;

class AdminPageComponent extends Component {
  render() {
    return (
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            borderRight: '1px solid #e0e0e0',
            height: '100vh',
          }}
        >
          <Typography variant="h6" noWrap component="div" sx={{ p: 2 }}>
            Admin Dashboard
          </Typography>
          <List>
            {['Users', 'Invites'].map((text, index) => (
              <ListItem button key={text} component={Link} to={`/admin/${text.toLowerCase()}`} sx={{ borderTop: index === 0 ? '1px solid #e0e0e0' : undefined,  borderBottom: '1px solid #e0e0e0', pt: '1rem', pb: '1rem' }}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
        >
          <Routes>
            <Route path="/" element={<UsersComponent />} />
            <Route path="/users" element={<UsersComponent />} />
            <Route path="/invites" element={<InvitesComponent />} />
          </Routes>
        </Box>
      </Box>
    );
  }
}

export default AdminPageComponent;
