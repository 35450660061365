import React from 'react';
import { connect } from 'react-redux';
import { Box, Button, TextField, Typography, Paper } from '@mui/material';
import { resetPassword } from '../../actions/user'; // Adjust the import path based on your project structure
import { WithRouterProps, withRouter } from '../../common/hooks/withParams';
import PasswordTextField from '../common/PasswordTextField';

interface ResetPasswordProps extends WithRouterProps {
  resetPassword: (id: string, code: string, password: string, confirmPassword: string) => Promise<boolean>;
}

interface ResetPasswordState {
  password: string;
  confirmPassword: string;
}

class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
  constructor(props: ResetPasswordProps) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
    };
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const id = searchParams.get('id');
    const code = searchParams.get('code');

    if (!id || !code) {
      window.location.href = '/login';
    }
  }

  handleResetPassword = async () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const id = searchParams.get('id');
    const code = searchParams.get('code');

    if (!id || !code) {
      return;
    }
    const result = await this.props.resetPassword(id, code, this.state.password, this.state.confirmPassword);
    if (result) {
      this.props.navigate('/login');
    }
  };

  render() {
    const { password, confirmPassword } = this.state;

    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Paper elevation={3} sx={{ p: 4, width: '100%', maxWidth: 400 }}>
          <Typography variant="h5" gutterBottom>
            Reset Password
          </Typography>
          <PasswordTextField
            label="New Password"
            name="password"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
            sx={{ mb: 2 }}
          />
          <PasswordTextField
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            variant="outlined"
            fullWidth
            value={confirmPassword}
            onChange={(e) => this.setState({ confirmPassword: e.target.value })}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={this.handleResetPassword} fullWidth>
            Reset Password
          </Button>
        </Paper>
      </Box>
    );
  }
}

const mapDispatchToProps = {
  resetPassword,
};

export default withRouter(connect(null, mapDispatchToProps)(ResetPassword as any));
