import { SelectOption } from "../components/common/Select";
import { USER_SERVER_UPDATE } from "../actions/types";
import { AlderonFriend, SocialMap } from "../api/social.types";
import { ServerInfo } from "../api/servers.types";

export interface UserServerState {
    map: SocialMap
    friends: SelectOption<AlderonFriend>[]
    servers: ServerInfo[]
}

export const UserServerInitialState: UserServerState = {
    map: {},
    friends: [],
    servers: [],
};

export const UserServerReducer = (state = UserServerInitialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case USER_SERVER_UPDATE:
            return {
                ...state,
                map: payload.map,
                friends: payload.friends,
                servers: payload.servers,
            };
    }
    return state;
}