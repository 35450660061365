import React from 'react'
import { useAuth } from './useAuth';
import { User } from '../../api';

export interface WithUpdateUserProps {
  updateUser: (user: Partial<User>) => void;
}

export const withUpdateUser = (Component: any) => {
  const Wrapper = (props: any) => {
    const { updateUser } = useAuth();
    return (
      <Component
        updateUser={updateUser}
        {...props}
        />
    );
  };
  
  return Wrapper;
};